/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Sharing details
 */
export interface Sharing { 
    /**
     * Is it shared with public?
     */
    sharedWithPublic?: boolean;
    /**
     * Is it shared with technical support?
     */
    sharedWithSupport?: boolean;
    /**
     * Is it shared with all users with given plans?
     */
    sharedWithPlans?: Array<string>;
    /**
     * Shared with read-only
     */
    viewers?: Array<string>;
    /**
     * Shared with write access
     */
    collaborators?: Array<string>;
}
