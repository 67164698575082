import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ToolframeComponent,
  ToolPanelComponent,
  ConfigurationComponent,
  ReducedLoginComponent,
  ReducedSignupComponent,
  ReducedResetPasswordComponent,
  ViewsContainerComponent,
  ViewComponent,
  CameraControlsComponent,
  SceneDirective,
  DirectionalLightDirective,
  AmbientLightDirective,
  OnshapeAssemblyComponent,
  ToolbarComponent,
  SelectedOutlinePassDirective,
  HoveredOutlinePassDirective,
  AlarmOutlinePassDirective,
  ViewCubeComponent,
  HemisphereLightDirective,
  MeasureDirective,
  OutlinePassDirective,
  AmbientOcclusionPassDirective,
  SpotLightDirective
} from '@harmanpa/ng-cae';
import { ViewerComponent } from './viewer/viewer.component';
import { ConfiguratorComponent } from './configurator/configurator.component';
import { VersionComponent } from './viewer/version/version.component';
import { DividerModule } from 'primeng/divider';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { MaterialFactory } from '../shared/services/material-factory.service';
import { MeshFactory } from '../shared/services/mesh-factory.service';
import { RouterModule } from '@angular/router';
import { BillOfMaterialsComponent } from './bill-of-materials/bill-of-materials.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { LayoutModule } from './layout/layout.module';
import { ParameterComponent } from './parameter/parameter.component';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { InputTextModule } from 'primeng/inputtext';
import { DocumentVersionComponent } from './bill-of-materials/document-version/document-version.component';
import { NodeTreeDirective } from './node-tree/node-tree.directive';
import { ReplaceCommasWithNewlinesPipe } from '../shared/pipes/noCommas.pipe';
import { BadgeModule } from 'primeng/badge';
import { LogoBrandComponent } from './logo-brand/logo-brand.component';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
  declarations: [
    BillOfMaterialsComponent,
    LogoBrandComponent,
    NodeTreeDirective,
    ConfiguratorComponent,
    DocumentVersionComponent,
    ParameterComponent,
    SearchBarComponent,
    ViewerComponent,
    VersionComponent
  ],
  imports: [
    ButtonModule,
    TooltipModule,
    CommonModule,
    DividerModule,
    RouterModule,
    ToolframeComponent,
    ToolPanelComponent,
    ConfigurationComponent,
    ReducedLoginComponent,
    ReducedSignupComponent,
    ReducedResetPasswordComponent,
    ViewsContainerComponent,
    ViewComponent,
    CameraControlsComponent,
    SceneDirective,
    DirectionalLightDirective,
    HemisphereLightDirective,
    AmbientLightDirective,
    OnshapeAssemblyComponent,
    ToolbarComponent,
    SelectedOutlinePassDirective,
    HoveredOutlinePassDirective,
    AlarmOutlinePassDirective,
    ViewCubeComponent,
    LayoutModule,
    TableModule,
    DropdownModule,
    FormsModule,
    TreeTableModule,
    TreeModule,
    InputTextModule,
    MeasureDirective,
    OutlinePassDirective,
    AmbientOcclusionPassDirective,
    SpotLightDirective,
    ReplaceCommasWithNewlinesPipe,
    BadgeModule,
    SelectButtonModule,
    DividerModule
  ],
  providers: [MaterialFactory, MeshFactory],
  exports: [ConfiguratorComponent, ViewerComponent],
})
export class UiModule { }
