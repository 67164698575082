import { Injectable } from "@angular/core";
import { ConfiguredAssembly, ConfiguredPart } from "../../../../generated-src";
import * as THREE from "three";
import { DefaultMeshFactory, MaterialType, MaterialStyling } from "@harmanpa/ng-cae";
import { FrontSide, Material, Mesh } from 'three';
import { SceneManipulation } from "./scene-manipulation.service";

@Injectable()
export class MeshFactory extends DefaultMeshFactory<ConfiguredAssembly, ConfiguredPart> {
  constructor(private sceneManipulation: SceneManipulation) {
    super();
  }

  addLinesToMesh(part: ConfiguredPart & { name?: string | undefined; }, mesh: THREE.Mesh, type?: MaterialType, materialStyling?: MaterialStyling): void {
    //Create lines
    super.addLinesToMesh(part, mesh, type, materialStyling);
    
    //Get lines
    const line = mesh.children.filter(child => child.type === "LineSegments2");
    if (part.name.startsWith("Survey")) { //If survey (wall)
      //Give priority to mesh to hide inside lines
      mesh.renderOrder = 2;
      line.forEach((child) => {
          child.renderOrder = 3;

          if (!this.sceneManipulation.isShowingParts) { //If its only showing fittings, ghost parts
            ((child as Mesh).material as Material).transparent = true;
            ((child as Mesh).material as Material).opacity = 0.2;
          }
      });
        
      //Make wall transparent
      (mesh.material as Material).transparent = true;
      (mesh.material as Material).opacity = 0.5;
      (mesh.material as Material).side = FrontSide;
    } else {  //Normal object
      if (type === MaterialType.WireframeWithHiddenEdges || type === MaterialType.SolidWithHiddenEdges || type === MaterialType.WireframeWithVisibleEdges) {
        mesh.layers.set(5); //Make objects not selectable (only lines will)
        
        mesh.renderOrder = 0;
        line.forEach((child) => {
          child.renderOrder = 1;
          //Set different layer for raycaster to detect these lines
          child.layers.enable(4);
        });
      } else if (type === MaterialType.Wireframe) {
        mesh.layers.set(5); //Make objects not selectable (only lines will)

        //Change render orders for lines to show when we are showing parts
        mesh.renderOrder = 1;
        line.forEach((child) => {
          child.renderOrder = 0;
          //Set different layer for raycaster to detect these lines
          child.layers.enable(4);
        });
      } else {  //Solid with edges
        mesh.layers.set(0); //Make objects selectable again

        //Change render orders for lines to show when we are showing parts
        mesh.renderOrder = 1;
        line.forEach((child) => {
          child.renderOrder = 0;

          //If its to show only fittings, change opacity of lines
          if (!this.sceneManipulation.isShowingParts && this.sceneManipulation.normalParts.includes(mesh)) {
            ((child as Mesh).material as Material).transparent = true;
            ((child as Mesh).material as Material).opacity = 0.2;
          }
        });
      }
    }
  }
}
