/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * OS used
 */
export interface OS { 
    /**
     * OS family
     */
    family?: string;
    /**
     * OS major version
     */
    major?: string;
    /**
     * OS minor version
     */
    minor?: string;
    /**
     * OS patch version
     */
    patch?: string;
    /**
     * OS patch minor version
     */
    patchMinor?: string;
}
