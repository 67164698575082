import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ConfigurationParameterEnum, ConfigurationParameterQuantity, ParameterValue } from '@harmanpa/ng-cae';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
})
export class ParameterComponent implements OnInit {
  @Input() parameterEnum: ConfigurationParameterEnum;
  @Input() parameterQty: ConfigurationParameterQuantity;
  _parameterValue: ParameterValue;
  _appliedParameterValue: ParameterValue;
  option: string;
  changed: boolean;

  @Input() set parameterValue(pv: ParameterValue) {
    this._parameterValue = pv;
    if (this._appliedParameterValue) {
      this.option = this._parameterValue.value;
      this.optionChange(this._parameterValue.value);
    }
  }

  get parameterValue() {
    return this._parameterValue;
  }

  @Input() set appliedParameterValue(apv: ParameterValue) {
    this._appliedParameterValue = apv;
    if (this._parameterValue) {
      this.changed = this._parameterValue.value !== this._appliedParameterValue.value;
    }
  }

  get appliedParameterValue() {
    return this._appliedParameterValue;
  }

  @Output() parameterValueChange = new EventEmitter<ParameterValue>();

  constructor() {
    this.option = 'Default';
  }

  ngOnInit(): void {
    // console.log(this.parameterEnum, this.parameterQty, this.parameterValue, this.appliedParameterValue);
    this.changed = false;
    this.optionChange(this._parameterValue.value);
  }

  optionChange(value: any) {
    if (value == null) {
      this.option = 'Default';
    }
    if (this._parameterValue.value !== this.option) {
      this._parameterValue.value = this.option;
      this.parameterValueChange.emit(this._parameterValue);
    }
    this.changed = this._parameterValue.value !== this._appliedParameterValue.value;
  }
}
