/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ConfigurationParameter } from './configurationParameter';

export interface OnshapeConfigurator { 
    documentId?: string;
    wvmType?: OnshapeConfigurator.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
    parameters?: Array<ConfigurationParameter>;
    documentName?: string;
    elementName?: string;
    elementType?: string;
}
export namespace OnshapeConfigurator {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
