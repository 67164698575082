/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { JsonNode } from './jsonNode';
import { WorkspaceOrVersionCreator } from './workspaceOrVersionCreator';

export interface WorkspaceOrVersion { 
    wv?: WorkspaceOrVersion.WvEnum;
    metadata?: { [key: string]: JsonNode; };
    parent?: string;
    creator?: WorkspaceOrVersionCreator;
    description?: string;
    microversion?: string;
    createdAt?: Date;
    modifiedAt?: Date;
    id?: string;
    name?: string;
}
export namespace WorkspaceOrVersion {
    export type WvEnum = 'Workspace' | 'Version';
    export const WvEnum = {
        Workspace: 'Workspace' as WvEnum,
        Version: 'Version' as WvEnum
    };
}
