/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OnshapeDocument { 
    baseURL?: string;
    documentId?: string;
    wvm?: OnshapeDocument.WvmEnum;
    workspaceId?: string;
    versionId?: string;
    microversionId?: string;
    elementId?: string;
}
export namespace OnshapeDocument {
    export type WvmEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmEnum = {
        Workspace: 'Workspace' as WvmEnum,
        Version: 'Version' as WvmEnum,
        Microversion: 'Microversion' as WvmEnum
    };
}
