import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
})


export class VersionComponent implements OnInit {
  @Input() version: string;

  constructor() {
  }

  ngOnInit() {
    console.log('App version: ',this.version);
  }
}

