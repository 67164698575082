import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfiguratorComponent } from './ui/configurator/configurator.component';
import { ViewerComponent } from './ui/viewer/viewer.component';
import { PendingChangesGuard } from '@harmanpa/ng-cae';



const appRoutes: Routes = [
    {
        path: 'configurator/:did/:wvm/:wvmid/e/:eid',
        component: ConfiguratorComponent,
        canDeactivate: [PendingChangesGuard],
    },
    { path: '**', component: ViewerComponent, runGuardsAndResolvers: 'always' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ],
    declarations: []
})
export class AppRoutingModule {
}
