/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Activity } from '../model/activity';
import { AppVersion } from '../model/appVersion';
import { Artefact } from '../model/artefact';
import { Company } from '../model/company';
import { DocumentSummary } from '../model/documentSummary';
import { Patch } from '../model/patch';
import { Reference } from '../model/reference';
import { Sharing } from '../model/sharing';
import { ShortCode } from '../model/shortCode';
import { ShortCodeResponse } from '../model/shortCodeResponse';
import { User } from '../model/user';
import { UserPermissions } from '../model/userPermissions';
import { VersionGraph } from '../model/versionGraph';
import { VersionLog } from '../model/versionLog';
import { VersionLogVersion } from '../model/versionLogVersion';
import { VersionedArtefact } from '../model/versionedArtefact';
import { VersionedArtefactPatch } from '../model/versionedArtefactPatch';
import { VersionedCompany } from '../model/versionedCompany';
import { VersionedCompanyDiff } from '../model/versionedCompanyDiff';
import { VersionedCompanyPatch } from '../model/versionedCompanyPatch';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = '/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * Add a new Company
     * @param body 
     * @param duplicateId ID of Company to duplicate
     * @param duplicateVersion Version of Company to duplicate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompany>;
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompany>>;
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompany>>;
    public addCompany(body?: Company, duplicateId?: string, duplicateVersion?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (duplicateId !== undefined && duplicateId !== null) {
            queryParameters = queryParameters.set('duplicate_id', <any>duplicateId);
        }
        if (duplicateVersion !== undefined && duplicateVersion !== null) {
            queryParameters = queryParameters.set('duplicate_version', <any>duplicateVersion);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompany>('post',`${this.basePath}/companies`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Use short code to access a shared resource
     * @param code Short code being used
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public applyShortCode(code: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCodeResponse>;
    public applyShortCode(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCodeResponse>>;
    public applyShortCode(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCodeResponse>>;
    public applyShortCode(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling applyShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCodeResponse>('get',`${this.basePath}/s/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Check if user can read specified Company
     * @param companyId ID of Company to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public canReadCompany(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public canReadCompany(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public canReadCompany(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public canReadCompany(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling canReadCompany.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('head',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete Company
     * @param companyId ID of Company to return
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public deleteCompany(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompany.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompanyPatch>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param versionId Version of Company
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteCompanyAvatar(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyAvatar.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteCompanyAvatar.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public deleteCompanyHeadAvatar(companyId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyHeadAvatar.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefactPatch>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific version of a Company
     * @param companyId ID of Company to delete version
     * @param versionId Version of Company to delete
     * @param archive Archive, rather than remove, data
     * @param failIfUsed Fail if the document is referenced by another undeleted document
     * @param updateUsed Update affected documents
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public deleteCompanyVersion(companyId: string, versionId: string, archive?: boolean, failIfUsed?: boolean, updateUsed?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteCompanyVersion.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling deleteCompanyVersion.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (archive !== undefined && archive !== null) {
            queryParameters = queryParameters.set('archive', <any>archive);
        }
        if (failIfUsed !== undefined && failIfUsed !== null) {
            queryParameters = queryParameters.set('failIfUsed', <any>failIfUsed);
        }
        if (updateUsed !== undefined && updateUsed !== null) {
            queryParameters = queryParameters.set('updateUsed', <any>updateUsed);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('delete',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Delete specific user
     * @param email Email of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public deleteUser(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public deleteUser(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public deleteUser(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('delete',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get complete documents for all Companies
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Company name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<VersionedCompany>>;
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<VersionedCompany>>>;
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<VersionedCompany>>>;
    public findAndGetCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<VersionedCompany>>('get',`${this.basePath}/companies/all`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get all Companies
     * @param mine Request owned by current user
     * @param shared Request shared with current user
     * @param includeDeleted Include deleted
     * @param canWrite Limit to documents with write-access
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param _public Request shared with public
     * @param name Company name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSummary>>;
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSummary>>>;
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSummary>>>;
    public findCompanies(mine?: boolean, shared?: boolean, includeDeleted?: boolean, canWrite?: boolean, multiWorkspace?: boolean, _public?: boolean, name?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (mine !== undefined && mine !== null) {
            queryParameters = queryParameters.set('mine', <any>mine);
        }
        if (shared !== undefined && shared !== null) {
            queryParameters = queryParameters.set('shared', <any>shared);
        }
        if (includeDeleted !== undefined && includeDeleted !== null) {
            queryParameters = queryParameters.set('includeDeleted', <any>includeDeleted);
        }
        if (canWrite !== undefined && canWrite !== null) {
            queryParameters = queryParameters.set('canWrite', <any>canWrite);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (_public !== undefined && _public !== null) {
            queryParameters = queryParameters.set('public', <any>_public);
        }
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSummary>>('get',`${this.basePath}/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get the current version of the application
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppVersion(observe?: 'body', reportProgress?: boolean): Observable<Array<AppVersion>>;
    public getAppVersion(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AppVersion>>>;
    public getAppVersion(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AppVersion>>>;
    public getAppVersion(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AppVersion>>('get',`${this.basePath}/version`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get specified version of specified Company
     * @param companyId ID of Company to return
     * @param versionId Version of Company to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param ifNoneMatch Version of Company already received, if no change to Company since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompany>;
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompany>>;
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompany>>;
    public getCompany(companyId: string, versionId: string, multiWorkspace?: boolean, complete?: boolean, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompany.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompany.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompany>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param versionId Version of Company
     * @param ifNoneMatch Version of Artefact already received, if no change to Artefact since version then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getCompanyAvatar(companyId: string, versionId: string, ifNoneMatch?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyAvatar.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyAvatar.');
        }


        let headers = this.defaultHeaders;
        if (ifNoneMatch !== undefined && ifNoneMatch !== null) {
            headers = headers.set('If-None-Match', String(ifNoneMatch));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param versionId Version of Company
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCompanyAvatarContent(companyId: string, versionId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyAvatarContent.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyAvatarContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getCompanyAvatarContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get diff between versions of Companies
     * @param companyId1 ID of first Company to compare
     * @param versionId1 Version of first Company to compare
     * @param companyId2 ID of second Company to compare
     * @param versionId2 Version of second Company to compare
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyDiff>;
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyDiff>>;
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyDiff>>;
    public getCompanyDiff(companyId1: string, versionId1: string, companyId2: string, versionId2: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId1 === null || companyId1 === undefined) {
            throw new Error('Required parameter companyId1 was null or undefined when calling getCompanyDiff.');
        }

        if (versionId1 === null || versionId1 === undefined) {
            throw new Error('Required parameter versionId1 was null or undefined when calling getCompanyDiff.');
        }

        if (companyId2 === null || companyId2 === undefined) {
            throw new Error('Required parameter companyId2 was null or undefined when calling getCompanyDiff.');
        }

        if (versionId2 === null || versionId2 === undefined) {
            throw new Error('Required parameter versionId2 was null or undefined when calling getCompanyDiff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompanyDiff>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId1))}/v/${encodeURIComponent(String(versionId1))}/diff/${encodeURIComponent(String(companyId2))}/v/${encodeURIComponent(String(versionId2))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get latest version of specified Company
     * @param companyId ID of Company to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param eTag Version of Company considered to be HEAD, if no newer Company is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompany>;
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompany>>;
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompany>>;
    public getCompanyHead(companyId: string, multiWorkspace?: boolean, complete?: boolean, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHead.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedCompany>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param eTag Version of Artefact considered to be HEAD, if no newer Artefact is found then return HTTP 304 response
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefact>;
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefact>>;
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefact>>;
    public getCompanyHeadAvatar(companyId: string, eTag?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHeadAvatar.');
        }


        let headers = this.defaultHeaders;
        if (eTag !== undefined && eTag !== null) {
            headers = headers.set('ETag', String(eTag));
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionedArtefact>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param extension File extension requested
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public getCompanyHeadAvatarContent(companyId: string, extension: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHeadAvatarContent.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling getCompanyHeadAvatarContent.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'binary/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar.${encodeURIComponent(String(extension))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getCompanyHeadAvatarLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHeadAvatarLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get history of specified Company
     * @param companyId ID of Company to return
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionGraph>;
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionGraph>>;
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionGraph>>;
    public getCompanyHistory(companyId: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyHistory.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionGraph>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get log of specified Company
     * @param companyId ID of Company to return
     * @param from First version to consider in log
     * @param to Last version to consider in log
     * @param limit Maximum number of versions in log
     * @param tag Tag of version to find
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param complete Fetch the entire data as stored
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionLog>;
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLog>>;
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLog>>;
    public getCompanyLog(companyId: string, from?: string, to?: string, limit?: number, tag?: string, multiWorkspace?: boolean, complete?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyLog.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (tag !== undefined && tag !== null) {
            queryParameters = queryParameters.set('tag', <any>tag);
        }
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (complete !== undefined && complete !== null) {
            queryParameters = queryParameters.set('complete', <any>complete);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLog>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/log`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Company permissions
     * @param companyId ID of Company to check
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyPermissions(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<UserPermissions>;
    public getCompanyPermissions(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserPermissions>>;
    public getCompanyPermissions(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserPermissions>>;
    public getCompanyPermissions(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyPermissions.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserPermissions>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/permissions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get sharing details of specified Company
     * @param companyId ID of Company to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanySharing(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public getCompanySharing(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public getCompanySharing(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public getCompanySharing(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanySharing.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Sharing>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/sharing`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get short code to share the specified Company
     * @param companyId ID of Company to share
     * @param mode Mode: copy or view or edit
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyShortCode(companyId: string, mode: string, observe?: 'body', reportProgress?: boolean): Observable<ShortCode>;
    public getCompanyShortCode(companyId: string, mode: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShortCode>>;
    public getCompanyShortCode(companyId: string, mode: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShortCode>>;
    public getCompanyShortCode(companyId: string, mode: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyShortCode.');
        }

        if (mode === null || mode === undefined) {
            throw new Error('Required parameter mode was null or undefined when calling getCompanyShortCode.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShortCode>('get',`${this.basePath}/s/companies/${encodeURIComponent(String(companyId))}/${encodeURIComponent(String(mode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Company
     * @param companyId ID of Company to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyUsages(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getCompanyUsages(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getCompanyUsages(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getCompanyUsages(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get version summary of specified Company
     * @param companyId ID of Company to return
     * @param versionId Version to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyVersionSummary(companyId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public getCompanyVersionSummary(companyId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public getCompanyVersionSummary(companyId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public getCompanyVersionSummary(companyId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyVersionSummary.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<VersionLogVersion>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Find usages of specified Company version
     * @param companyId ID of Company to return
     * @param versionId Version of Company to return
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyVersionUsages(companyId: string, versionId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Reference>>;
    public getCompanyVersionUsages(companyId: string, versionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Reference>>>;
    public getCompanyVersionUsages(companyId: string, versionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Reference>>>;
    public getCompanyVersionUsages(companyId: string, versionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling getCompanyVersionUsages.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling getCompanyVersionUsages.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Reference>>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/usages`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMe(observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getMe(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getMe(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getMe(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/me`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get activity of current user
     * @param from From date
     * @param to To date
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyActivity(from?: string, to?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Activity>>;
    public getMyActivity(from?: string, to?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Activity>>>;
    public getMyActivity(from?: string, to?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Activity>>>;
    public getMyActivity(from?: string, to?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (from !== undefined && from !== null) {
            queryParameters = queryParameters.set('from', <any>from);
        }
        if (to !== undefined && to !== null) {
            queryParameters = queryParameters.set('to', <any>to);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Activity>>('get',`${this.basePath}/users/me/activity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of specific user
     * @param email Email of user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(email: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public getUser(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public getUser(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public getUser(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('get',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get details of visible users
     * @param guests Whether to include guest users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(guests?: boolean, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getUsers(guests?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getUsers(guests?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getUsers(guests?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (guests !== undefined && guests !== null) {
            queryParameters = queryParameters.set('guests', <any>guests);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Invite new user(s) using email address(es)
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteUsers(body?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public inviteUsers(body?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public inviteUsers(body?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public inviteUsers(body?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'text/plain'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<User>>('post',`${this.basePath}/users/invitations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Patch specific version of a Company
     * @param companyId ID of Company to update
     * @param versionId Version of Company to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public patchCompany(companyId: string, versionId: string, body?: Patch, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling patchCompany.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling patchCompany.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompanyPatch>('patch',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Revoke an invitation
     * @param code Invitation code
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public revokeInvitation(code: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public revokeInvitation(code: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public revokeInvitation(code: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public revokeInvitation(code: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling revokeInvitation.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/users/invitations/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Get Company summary
     * @param companyId ID of Company to check
     * @param includeAttributes DEPRECATED Include information such as number of versions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public summariseCompany(companyId: string, includeAttributes?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSummary>;
    public summariseCompany(companyId: string, includeAttributes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSummary>>;
    public summariseCompany(companyId: string, includeAttributes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSummary>>;
    public summariseCompany(companyId: string, includeAttributes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling summariseCompany.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (includeAttributes !== undefined && includeAttributes !== null) {
            queryParameters = queryParameters.set('include_attributes', <any>includeAttributes);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSummary>('get',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/summary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company to return
     * @param versionId Version of Company
     * @param extension File extension requested
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateAvatarContentMultipartForm(companyId: string, versionId: string, extension: string, file?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateAvatarContentMultipart.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateAvatarContentMultipart.');
        }

        if (extension === null || extension === undefined) {
            throw new Error('Required parameter extension was null or undefined when calling updateAvatarContentMultipart.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar.${encodeURIComponent(String(extension))}`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update specific version of a Company
     * @param companyId ID of Company to update
     * @param versionId Version of Company to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public updateCompany(companyId: string, versionId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompany.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateCompany.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompanyPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param versionId Version of Company
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateCompanyAvatar(companyId: string, versionId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyAvatar.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateCompanyAvatar.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/v/${encodeURIComponent(String(versionId))}/avatar`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update head version of Company
     * @param companyId ID of Company to update
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedCompanyPatch>;
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedCompanyPatch>>;
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedCompanyPatch>>;
    public updateCompanyHead(companyId: string, body?: Company, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyHead.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedCompanyPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param companyId ID of Company
     * @param body 
     * @param multiWorkspace Fetch documents across multiple workspaces
     * @param updateAllReferences Update affected documents owned by current user
     * @param updateMyReferences Update affected documents owned by any user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'body', reportProgress?: boolean): Observable<VersionedArtefactPatch>;
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionedArtefactPatch>>;
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionedArtefactPatch>>;
    public updateCompanyHeadAvatar(companyId: string, body?: Artefact, multiWorkspace?: boolean, updateAllReferences?: boolean, updateMyReferences?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyHeadAvatar.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (multiWorkspace !== undefined && multiWorkspace !== null) {
            queryParameters = queryParameters.set('multiWorkspace', <any>multiWorkspace);
        }
        if (updateAllReferences !== undefined && updateAllReferences !== null) {
            queryParameters = queryParameters.set('updateAllReferences', <any>updateAllReferences);
        }
        if (updateMyReferences !== undefined && updateMyReferences !== null) {
            queryParameters = queryParameters.set('updateMyReferences', <any>updateMyReferences);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionedArtefactPatch>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/avatar`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update sharing details of specified Company
     * @param companyId ID of Company to update
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanySharing(companyId: string, body?: Sharing, observe?: 'body', reportProgress?: boolean): Observable<Sharing>;
    public updateCompanySharing(companyId: string, body?: Sharing, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Sharing>>;
    public updateCompanySharing(companyId: string, body?: Sharing, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Sharing>>;
    public updateCompanySharing(companyId: string, body?: Sharing, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanySharing.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Sharing>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/sharing`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update version summary of specified Company
     * @param companyId ID of Company to return
     * @param versionId Version to return
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe?: 'body', reportProgress?: boolean): Observable<VersionLogVersion>;
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<VersionLogVersion>>;
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<VersionLogVersion>>;
    public updateCompanyVersionSummary(companyId: string, versionId: string, body?: VersionLogVersion, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompanyVersionSummary.');
        }

        if (versionId === null || versionId === undefined) {
            throw new Error('Required parameter versionId was null or undefined when calling updateCompanyVersionSummary.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<VersionLogVersion>('put',`${this.basePath}/companies/${encodeURIComponent(String(companyId))}/log/${encodeURIComponent(String(versionId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update details of current user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMe(body?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateMe(body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateMe(body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateMe(body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/users/me`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * Update details of specific user
     * @param email Email of user
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(email: string, body?: User, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public updateUser(email: string, body?: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public updateUser(email: string, body?: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public updateUser(email: string, body?: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling updateUser.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('put',`${this.basePath}/users/${encodeURIComponent(String(email))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
