/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DocumentsGetDocumentResponseOwner { 
    type?: number;
    name?: string;
    id?: string;
    href?: string;
}
