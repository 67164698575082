import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Configurator} from '@harmanpa/ng-cae';
import {HttpClient} from '@angular/common/http';
import {WorkspaceOrVersion} from '../../../../../generated-src';
import {Router} from '@angular/router';

@Component({
  selector: 'app-document-version',
  templateUrl: './document-version.component.html',
})
export class DocumentVersionComponent implements OnChanges {
  @Input() configurator: Configurator;
  @Input() online = true;
  versions: WorkspaceOrVersion[] = [];
  versionNames: string[] = [];
  currentVersionName: string;
  elements: OnshapeElement[] = [];
  elementNames: string[] = [];
  currentElementName: string;

  constructor(private http: HttpClient, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.configurator && this.versions.length === 0) {
      this.http.get<WorkspaceOrVersion[]>(`/api/documents/${this.configurator.documentId}/versions?metadata=true`)
          .subscribe(res => {
            // Filter the response to include published or current
            this.versions = res.filter(worv => worv.id === this.configurator.wvmId || worv.metadata['Released']);
            this.currentVersionName = this.versions.find(worv => worv.id === this.configurator.wvmId)?.name;
            this.versionNames = this.versions.map(v => v.name);
            const wvm = this.configurator.wvmType.charAt(0).toLowerCase();
            this.http.get<OnshapeElement[]>(`/api/documents/${this.configurator.documentId}/${wvm}/${this.configurator.wvmId}`)
                .subscribe(elements => {
                  this.elements = elements.filter(element => this.configurator.elementId === element.id
                      || (element.elementType === 'ASSEMBLY' && !element.name.toLowerCase().startsWith('wip')));
                  this.currentElementName = this.elements.find(element => this.configurator.elementId === element.id)?.name;
                  this.elementNames = this.elements.map(e => e.name).sort((a, b) => a.localeCompare(b));
                });
          });
    }
  }

  updateModel(version: string): void {
    if (version && this.currentVersionName !== version) {
        const current = this.versions.find(v => v.name === this.currentVersionName);
        const replacement = this.versions.find(v => v.name === version);
        const currentUrl = this.router.url;
        const urlParts = currentUrl.split('/')
          .filter(seg => seg.length > 0)
          .map(seg => decodeURIComponent(seg))
          .map((seg, i) => i === 0 ? '/' + seg : seg);
        if (current.wv === 'Version') {
            // Remove the last part, if it was already in a Version
            urlParts.pop();
        }
        if (replacement.wv === 'Version') {
            // Add the new part
            urlParts.push(version);
        }
        // Redirect to the new URL
        this.currentVersionName = version;
        this.router.navigate(urlParts);
    }
  }

  updateElement(element: string): void {
    if (element && this.currentElementName !== element) {
        const current = this.versions.find(v => v.name === this.currentVersionName);
        const currentUrl = this.router.url;
        const urlParts = currentUrl.split('/')
          .filter(seg => seg.length > 0)
          .map(seg => decodeURIComponent(seg))
          .map((seg, i) => i === 0 ? '/' + seg : seg);
        let versionPart: string | undefined;
        if (current.wv === 'Version') {
            // Remove the last part, if it was already in a Version
            versionPart = urlParts.pop();
        }
        // Remove the current element
        urlParts.pop();
        urlParts.push(element);
        if (versionPart) {
            // Add the new part
            urlParts.push(versionPart);
        }
        // Redirect to the new URL
        this.currentElementName = element;
        this.router.navigate(urlParts);
    }
  }
}

export interface OnshapeElement {
  name?: string;
  id?: string;
  elementType?: string;
  lengthUnits?: string;
  angleUnits?: string;
  massUnits?: string;
}
