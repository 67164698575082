import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  OnshapeAssembly,
  TableRow,
  AbstractObject3D,
  ViewComponent,
  automaticUnsubscribe,
  HoverService,
  MeshService,
  MaterialType,
  ExplodeService,
  Configurator,
} from "@harmanpa/ng-cae";
import {
  Observable,
  distinctUntilChanged,
  filter,
  first,
  forkJoin,
  fromEvent,
  interval,
  map,
  of,
  switchMap,
} from "rxjs";
import { BoMTable } from "generated-src";
import { TreeNode } from "primeng/api";
import { Tree } from "primeng/tree";
import { HttpClient } from "@angular/common/http";
import { Mesh, Object3D, Vector3 } from "three";
import { SceneManipulation } from "src/app/shared/services/scene-manipulation.service";

@Component({
  selector: "app-bill-of-materials",
  templateUrl: "./bill-of-materials.component.html",
})
export class BillOfMaterialsComponent implements OnInit, OnChanges {
  @Input() assembly: OnshapeAssembly;
  @Input() view: ViewComponent;
  @Input() mobile: boolean;
  @Input() description: string;
  @Input() online: boolean = false;
  @Input() configurator: Configurator;
  /** Emitter to set the clicked objects (for measures) */
  @Output() clickedObjects = new EventEmitter<AbstractObject3D<Object3D>[]>();
  /** Tree element to detect clicks on it*/
  @ViewChild("treeElem") treeElem: Tree;

  assemblyTree: BoMTreeNode[];
  materialIDs: string[] = [];
  projectName: string = ' ';
  /** Contains current parts that are selected */
  partsClicked: AbstractObject3D<Object3D>[] = [];
  /** Boolean to know if it is to ignore next hover subscription */
  ignoreNextHover = false;
  /** Boolean to know if it is to ignore next select subscription */
  ignoreNextSelect = false;
  /** Contains the node that is being isolated */
  isolatedInstance: TableNode;
  /** Function to compare arrays */
  private compareArrays = (a, b) =>
  a.length === b.length && a.every((element, index) => element === b[index]);

  loading: boolean = false;
  
  constructor(
    private zone: NgZone,
    private http: HttpClient,
    private hoverService: HoverService,
    private meshService: MeshService,
    private cd: ChangeDetectorRef,
    private sceneManipulation: SceneManipulation,
    private explodeService: ExplodeService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    if (changes["vm"] && !changes["vm"].isFirstChange()) {
      this.loadTree();
    }
    if (changes.assembly && changes["assembly"].currentValue) {
      this.loadTree();
      this.listenForDocumentClick();
    }
    this.projectName = this.configurator?.documentName;
  }

  ngOnInit(): void {
    if (this.view.view) {
      this.listenForHoverSelect();
    } else {
      this.view.getViewWhenReady().subscribe(() => {
        this.listenForHoverSelect();
      });
    }
    //Resets the selected objects when Render type changes (to prevent bugs)
    this.meshService.getMaterialType().subscribe((materialType) => {
      this.view?.view?.getSelectionManager().setSelectedByPath([]);
      this.partsClicked.forEach((part) => {
        this.hoverService.resetHoverClick(part.getObject(), false);
      });
    });
  }


  /**
   * Listens for hover and select on the scene to hover/select on the html tree element
   */
  listenForHoverSelect(): void {
    this.zone.runOutsideAngular(() => {
      // Listen for hover and selection
      this.view.view.hoverChange
        .pipe(
          //Filter to prevent subscription being called when hover is triggered by the table
          filter(() => {
            const ignore = this.ignoreNextHover;
            if (ignore) {
              this.ignoreNextHover = false;
            }
            return !ignore;
          }),
          map((objs) => this.getPaths(objs, true)),
          distinctUntilChanged(this.compareArrays),
          switchMap((paths) => {
            return of(paths);
          })
        )
        .subscribe((paths) => {
          this.hoverRowScene(paths);
        });

      this.view.view.selectionChange
        .pipe(
          //Filter to prevent subscription being called when select is triggered by the table
          filter(() => {
            const ignore = this.ignoreNextSelect;
            if (ignore) {
              this.ignoreNextSelect = false;
            }
            return !ignore;
          }),
          map((objs) => this.getPaths(objs, true))
        )
        .subscribe((paths) => {
          this.selectRowScene(paths);
        });
    });
  }

  /**
   * Listens for document click to unselect objects
   */
  listenForDocumentClick(): void {
    fromEvent(document, "click")
      .pipe(
        // Filter canvas clicks and tree clicks
        filter(
          (event) =>
            !this.treeElem?.el.nativeElement.contains(event.target) &&
            !document
              .querySelector("cae-views-container")
              ?.contains(event.target as Node)
        ),
        automaticUnsubscribe(this)
      )
      .subscribe(() => {
        // Unselect nodes
        this.view.view.getSelectionManager().setSelectedByPath([]);
        //Remove selected/hover from all tree
        this.assemblyTree?.forEach((node) => this.recurseTreeDownards(node, false, false, undefined));
      });
  }

  /**
   * Hover a node (when hovering on the table)
   * @param node - the Node object
   */
  hoverRowTable(event: PointerEvent, node: TableRowNode): void {
    if (event.pointerType === 'mouse') {  //Only enable hover from table if its a mouse to prevent bugs from touch devices
      // Get occurrences from node
      const occurrences = this.getOccurrencesFromNode(node);
      // Hover occurences on table
      this.recurseTreeUpwards(node, undefined, true, undefined);
      // Hover occurences on scene
      this.ignoreNextHover = true;
      this.view.view.getSelectionManager().setHoveredByPath(occurrences, true);
    }
  }

  /**
   * Hover a row (when hovering on the scene)
   * @param paths - string[] containing all paths currently hovered
   */
  hoverRowScene(paths: string[][]): void {
    //Set all parts hover to false
    this.assemblyTree?.forEach((_node) => {
      this.recurseTreeDownards(_node, undefined, false, undefined);
    });

    //Get nodes from paths
    const nodes = this.getNodesFromPath(this.assemblyTree, paths);
    const nodeKeys = Object.keys(nodes);

    if (nodeKeys.length) {
      // Set nodes and their parents as hovered on tree
      nodeKeys.forEach((key) => {
        nodes[key].forEach((node) => {
          this.recurseTreeUpwards(node, undefined, true, undefined);
        });
      });
    }
  }

  /**
   * Unhovers
   */
  unHover(): void {
    //Unhovers on the scene
    this.view.view.getSelectionManager().setHoveredByPath([]);
    //Unhovers on the table
    this.assemblyTree?.forEach((_node) => {
      this.recurseTreeDownards(_node, undefined, false, undefined);
    });
  }

  /**
   * Selects nodes (when clicking on the table)
   * @param $event - Mouse event
   * @param node - the Node object
   */
  selectRowTable($event: PointerEvent, node: TableNode): void {
    //If shift is being pressed (if tablet or touch, shift is true to allow multiselect)
    const isShiftKeyed = $event.pointerType !== "mouse" || $event.shiftKey;

    if (!isShiftKeyed) {  //If there isnt a shift click, unselect all selected nodes from the table and close table
      this.assemblyTree?.forEach((_node) => {
        this.recurseTreeDownards(_node, false, undefined, false);
      });
    }

    // Get occurences from node
    const occurences = this.getOccurrencesFromNode(node);
    // Get abstractObject and object (Mesh) from occurences
    const parts = occurences
      .map((occ) => this.view.sceneComponent.findByPath(occ))
      .filter((object) => !object.isHelper());
    const objects = parts.map((object) =>
      this.getMeshFromObject(object.getObject())
    );

    const selectedObjects = []; //Get already selected objects
    let isSameSelection = true;
    parts.forEach((part, index) => {
      if (this.view.view.getSelectionManager().getSelected().includes(part)) {
        selectedObjects.push(objects[index]);
      } else {
        isSameSelection = false;
      }
    });

    if (isShiftKeyed) {
      this.ignoreNextSelect = true; // Ignore triggered selection since a change will be made

      if (
        selectedObjects.length !== parts.length &&
        selectedObjects.length > 0
      ) { //If there are already selected objects, deselect them.
        //Unselect already selected objects on the scene
        this.view.view
          .getSelectionManager()
          .toggleSelected(selectedObjects, true);

        //Removed those already selected objects (that will be deselected) from the arrays to only select the correct objects
        for (let index = objects.length - 1; index >= 0; index--) {
          const found = selectedObjects.indexOf(objects[index]);
          if (found === -1) {
            parts.splice(index, 1);
            occurences.splice(index, 1);
          }
        }
      } else {
        this.view.view.getSelectionManager().toggleSelected(objects, true);
      }
    } else {  //Single click
      if (!isSameSelection) { // If the object to be added is not selected, then a made will be made, so ignore next call
        this.ignoreNextSelect = true;
      }

      // Select object
      this.view.view.getSelectionManager().setSelected(objects, true);

      // Reset objects clicked
      this.partsClicked.forEach((part) => {
        this.hoverService.resetHoverClick(part.getObject(), false);
      });
      this.partsClicked = [];
    }

    // Gets nodes from occurrences
    const nodes = this.getNodesFromPath(this.assemblyTree, occurences);
    const nodeKeys = Object.keys(nodes);

    //Open tree to selected nodes
    const changedNodes = [];
    nodeKeys.forEach((keys) => {
      nodes[keys].forEach((thisNode) => {
        if (!changedNodes.includes(thisNode)) { //Prevent duplicates
          //Set selected value
          (thisNode.data as BoMTreeData).selected =
            (thisNode.data as BoMTreeData).selected && isShiftKeyed
              ? 0
              : (thisNode.data as BoMTreeData).object.occurences.length;
          
          //Loops through the table to open the table
          this.recurseTreeUpwards(thisNode, undefined, undefined, true);
          changedNodes.push(thisNode);
        }
      });
    });

    // Select objects on hover service (to give color)
    parts.forEach((object) => {
      const objectSelected = this.partsClicked.includes(object);
      if (objectSelected && isShiftKeyed) {
        // If it is to remove object
        this.partsClicked = this.partsClicked.filter((obj) => obj !== object);
        this.hoverService.resetHoverClick(object.getObject(), false);
      }

      if (!objectSelected) {
        // Add selected object
        this.partsClicked.push(object);
        this.hoverService.hoverClick(object.getObject(), true);
      }
    });

    // Emit clicked objects to update measures
    this.clickedObjects.emit(this.view.view.getSelectionManager().getSelected());

    //Update parents to have the correct parents selected according to the selected objects
    this.assemblyTree?.forEach((_node) => {
      this.updatesSelectParents(_node);
    });
    
    // Scroll to the first selected node
    if (nodeKeys.length > 0) {
      interval(0).pipe(first()).subscribe(() => {
        this.scrollToElement(nodes[nodeKeys[0]][0].data.element);
      });
    }
  }

  /**
   * Selects rows (when clicking on the scene)
   * @param paths: the paths of the currently selected objects
   */
  selectRowScene(paths: string[][]): void {
    // Reset all hover clicks
    this.partsClicked.forEach((part) => {
      this.hoverService.resetHoverClick(part.getObject(), false);
    });

    // Get nodes from paths
    const nodes = this.getNodesFromPath(this.assemblyTree, paths);
    const nodeKeys = Object.keys(nodes);

    if (nodeKeys.length) {  // Close tree if there is any clicked node
      this.assemblyTree?.forEach((_node) => {
        this.recurseTreeDownards(_node, false, undefined, false);
      });
    }

    nodeKeys.forEach((keys) => {  //Reset selected since it will be recalculated
      nodes[keys].forEach((node) => {
        (node.data as BoMTreeData).selected = 0;
      });
    });

    // Open tree on the selected nodes and update the selected number
    nodeKeys.forEach((keys) => {
      nodes[keys].forEach((node) => {
        this.recurseTreeUpwards(node, true, false, true);
      });
    });

    //Get the selected objects
    const objects = paths.map((path) =>
      this.view.sceneComponent.findByPath(path)
    );

    // Emit clicked objects to update measures
    this.clickedObjects.emit(objects);

    // Update objects clicked
    this.partsClicked = [];
    this.partsClicked.push(...objects);

    // Select objects on hover service (to give color)
    objects.forEach((object) => {
      this.hoverService.hoverClick(object.getObject(), true);
    });

    if (paths.length === 0) { //If selected empty, remove selected from all tree
      this.assemblyTree?.forEach((node) => this.recurseTreeDownards(node, false, false, false));
    } else {
      //Scroll to the last selected node
      interval(0).pipe(first()).subscribe(() => {
        this.scrollToElement(nodes[paths[paths.length-1][0]]?.[0].data.element);
      });
    }

    this.detectChanges();
  }
  
  /**
   * Function to scroll to an html Element
   * @param element - the element to scroll to.
   */
  scrollToElement(element: HTMLElement): void {
    //Get size of this element and the tree element to calculate the scroll necessary for this element to be on the center of screen
    const elementRect = element?.getBoundingClientRect();
    if (elementRect) {
      const treeRect = this.treeElem.el.nativeElement.getBoundingClientRect();

      const offset = elementRect.top - treeRect.top + this.treeElem.el.nativeElement.scrollTop + (elementRect.height / 2) - (treeRect.height / 2);
      //Scroll element to the middle of the tree
      this.treeElem.el.nativeElement.scrollTo({
          behavior: "smooth",
          top: offset
      });
    }
  }

  /**
   * Loops through object until it finds the mesh
   * (if it doesnt find it returns the object that was sent)
   * @param object - the object to search
   * @returns
   */
  getMeshFromObject(object: Object3D): Mesh | Object3D {
    if (object["isMesh"]) {
      return object as Mesh;
    } else {
      for (const child of object.children) {
        const mesh = this.getMeshFromObject(child);
        if (mesh) {
          return mesh;
        }
      }
    }
    return object;
  }

  /**
   * Recursive function that gets all occurences from the given node
   * @param node - the node from the tree (can be any node)
   * @param parts - the occurrences found
   * @returns the list with all occurences found
   */
  getOccurrencesFromNode(
    node: BoMTreeNode,
    occurences: string[][] = []
  ): string[][] {
    if (node.type === "instance") {
      node.children.forEach((child) => {
        this.getOccurrencesFromNode(child, occurences);
      });
    } else if (node.type === "table") {
      (node as TableNode).table.forEach((child) => {
        this.getOccurrencesFromNode(child, occurences);
      });
    } else {
      occurences.push(...(node.data as BoMTreeData).object.occurences);
    }
    return occurences;
  }

  getPaths(
    objs: AbstractObject3D<Object3D>[],
    includeParents: boolean
  ): string[][] {
    if (includeParents) {
      return objs
        .map((obj) => this.view.sceneComponent.getPath(obj))
        .filter((path) => path != null)
        .map((path) =>
          (path as string[]).map((v, i, a) => [a.slice(0, i + 1).join(",")])
        )
        .reduce((a, b) => a.concat(b), []);
    } else {
      return objs
        .map((obj) => this.view.sceneComponent.getPath(obj))
        .filter((path) => path != null)
        .map((path) => [(path as string[]).join(",")]);
    }
  }

  /**
   * Recursive function that loops through tree to get nodes from path
   * @param treeNodes - the tree nodes
   * @param paths - the paths
   * @param nodesFound
   * @returns an array with the nodes found
   */
  getNodesFromPath(
    treeNodes: BoMTreeNode[],
    paths: string[][],
    nodesFound: { [id: string]: BoMTreeNode[] } = {}
  ): { [id: string]: BoMTreeNode[] } {
    treeNodes?.forEach((node) => {
      if (node?.data && node.data["object"]) {
        for (let i = 0; i < paths.length; i++) {
          // Loop through paths
          const path = paths[i].toString();
          if (
            (node.data as BoMTreeData).object.occurences.find(
              (occ) => occ.toString() === path
            ) &&
            !nodesFound[path]?.includes(node)
          ) {
            // If found, add node
            if (nodesFound[path]) {
              nodesFound[path].push(node);
            } else {
              nodesFound[path] = [node];
            }
          }
        }
      }

      // Recurse tree
      if (node.children != null) {
        this.getNodesFromPath(
          node.children,
          paths,
          nodesFound
        );
      } else if (node["table"] != null) {
        this.getNodesFromPath(
          node["table"],
          paths,
          nodesFound
        );
      }
    });

    return nodesFound;
  }

  /**
   * Open/Closes Selects/Deselects Hovers/Unhovers the tree recursively Upwards
   * @param node - the node to start (it will loop through all parents)
   * @param select - it will select or deselect accordingly, if undefined it wont do anything
   * @param hover - it will hover or unhover accordingly, if undefined it wont do anything
   * @param open  - it will open or close accordingly, if undefined it wont do anything
   */
  recurseTreeUpwards(node: BoMTreeNode, select?: boolean, hover?: boolean, open?: boolean): void {
    if (node.type !== "table") {
      if (open !== undefined) {
        node.expanded = open;
      }
      if (select !== undefined) {
        if (select) {
          node.data.selected++;
        } else {
          node.data.selected = 0;
        }
      }
      if (hover !== undefined) {
        node.data.hovered = hover;
        //Add/Remove hovered row class to element
        if (hover) {
          node.data?.element?.classList.add("hoveredRow");
        } else {
          node.data?.element?.classList.remove("hoveredRow");
        }
      }
    }
    
    if (node.parent) {
      this.recurseTreeUpwards(node.parent, select, hover, open);
    }
  }

  /**
   * Open/Closes Selects/Deselects Hovers/Unhovers the tree recursively downwards
   * @param node - the node to start (it will loop through all childs)
   * @param select - it will select or deselect accordingly, if undefined it wont do anything
   * @param hover - it will hover or unhover accordingly, if undefined it wont do anything
   * @param open  - it will open or close accordingly, if undefined it wont do anything
   */
  recurseTreeDownards(node: BoMTreeNode, select?: boolean, hover?: boolean, open?: boolean): void {
    if (node.type !== "table") {
      if (open !== undefined) {
        node.expanded = open;
      }
      if (select !== undefined) {
        if (select) {
          node.data.selected++;
        } else {
          node.data.selected = 0;
        }
      }
      if (hover !== undefined) {
        node.data.hovered = hover;
        //Add/Remove hovered row class to element
        if (hover) {
          node.data?.element?.classList.add("hoveredRow");
        } else {
          node.data?.element?.classList.remove("hoveredRow");
        }
      }
    }
    
    if (node.type === "instance") {
      node.children.forEach((childNode) => {
        this.recurseTreeDownards(childNode, select, hover, open);
      });
    } else if (node.type === "table") {
      (node as TableNode).table.forEach((childNode) => {
        this.recurseTreeDownards(childNode, select, hover, open);
      });
    }
  }

  /**
   * Updates the select property recursively from parents.
   * Loops childs and checks for selection, if any, parent will also be selected.
   * @param node - The node to start checking from.
   */
  updatesSelectParents(node: BoMTreeNode): number {
    let howManySelectedChilds = 0;

    // Loop through all children
    if (node.type === "instance") {
      node.children.forEach(childNode => {
        const selectedChilds = this.updatesSelectParents(childNode);
        howManySelectedChilds += selectedChilds;
      });
    } else if (node.type === "table") {
      (node as TableNode).table.forEach(childNode => {
        const selectedChilds = this.updatesSelectParents(childNode);
        howManySelectedChilds += selectedChilds;
      });
    }

    //If its not a value from the table (a parent), then set selected accordingly
    if (node.type !== "table" && node.type !== undefined) {
      // Update the node's select based on children
      node.data.selected = howManySelectedChilds;
    }

    // Return whether any descendant has select: true
    return howManySelectedChilds || (node.data?.selected || 0);
  }

  /**
   * Isolates an instance
   * @param node - the node that needs to be isolated
   */
  isolateInstance(node: TableNode, event: Event): void {
    event.stopPropagation();
    //collapse and expand bom
    this.expandOrCollapseBom('collapse');
    this.view.view.getSelectionManager().setSelectedByPath([]); // Resets selection to prevent bugs
    this.meshService.setMaterialType(MaterialType.SolidWithEdges);  // Resets scene to SolidWithEdges to prevent bugs
    this.isolatedInstance = node;
    // Get occurences from node
    const occurences = this.getOccurrencesFromNode(node);
    this.sceneManipulation.isolateInstance(occurences);
    //Reset animation
    this.explodeService.animateLevel(0, 1.0);
    //open the selected node
    this.recurseTreeDownards(node, undefined, undefined, true);
    
  }

  /**
   * Connects an instance
   */
  connectInstance(event: Event): void {
    event.stopPropagation();
    this.isolatedInstance = undefined;
    this.expandOrCollapseBom('collapse');
    this.sceneManipulation.connectInstance();
    this.meshService.setMaterialType(MaterialType.SolidWithEdges);  // Resets scene to SolidWithEdges to prevent bugs
    //Reset animation
    this.explodeService.animateLevel(0, 1.0);
  }

  // Tree Table Definition
  loadTree(): void {
    const tree = this.groupByInstanceAndRoom(this.assembly.bom);
    const treeGroups = this.subgroupPartsAndFittings(tree);
    this.getMaterialIds().subscribe((matIds) => {
      const grainCritical = matIds;
      this.mapMaterialWithParts(treeGroups, grainCritical);
    });
    this.createTree(treeGroups).subscribe((assTree) => {
      this.assemblyTree = assTree;
      // Add parents to tree to be able to open the tree on the part clicked
      this.assemblyTree.forEach((node) => {
        this.addParentsToTree(node);
      });

      this.assemblyTree = assTree.sort((a, b) => a.key.localeCompare(b.key));
      this.loading = false;
    });
  }

  // Step 1: Group by `instancenumber` and `room`
  groupByInstanceAndRoom(data: BoMTable): { [key: string]: RoomInstance } {
    return data.rows.reduce((acc, item) => {
      const room = item.data.room;

      if (room !== "N/A") {
        const instance = item.data.instancenumber;
        const key = `${instance}-${room}`;
        if (!acc[key]) {
          acc[key] = {
            name: room + "-" + instance,
            qty: 1,
            type: "instance",
            items: [],
          };
        }
        acc[key].items.push(item);
      }

      return acc;
    }, {} as { [key: string]: RoomInstance });
  }

  // Step 2: Sub-group within each group (instance+room)
  subgroupPartsAndFittings(tree: { [key: string]: RoomInstance }): {
    [key: string]: Group;
  } {
    const grouped: { [key: string]: Group } = {};
    Object.keys(tree).forEach((key) => {
      const group: Group = {
        name: tree[key].name,
        qty: tree[key].qty,
        type: tree[key].type,
        parts: [],
        fittings: [],
      };
      tree[key].items.forEach((item) => {
        const material = item.data.materialid;
        const fittingCode = item.data.fittingcode;
        const occurrences = item.occurrences;
        const part = {
          name: item.data.name,
          codereference: item.itemSource.partId,
          qty: item.data.quantity,
          material: item.data.materialreference,
          materialid: item.data.materialid,
          graindirection: item.data.graindirection,
          // configuration: item.data.fabricationmethod, // for fittings
          codefitting: item.data.fittingcode, // for fittings
          // link: item.data.partNumber, // for fittings
          occurences: occurrences ? occurrences : [],
        };

        if (material !== "N/A") {
          const foundPart = group.parts.find(
            (_part) => _part.name === part.name
          );
          if (foundPart) {
            foundPart.qty += part.qty;
            foundPart.occurences.push(...part.occurences);
          } else {
            group.parts.push(part);
            this.materialIDs.push(part.materialid);
          }
        } else if (fittingCode !== "N/A") {
          group.fittings.push(part);
        }
      });
      grouped[key] = group;
    });
    //Sort parts and fittings alphabetically
    Object.entries(grouped).forEach(entry => {
      entry[1].parts.sort((a, b) => a.name.localeCompare(b.name));
      entry[1].fittings.sort((a, b) => a.name.localeCompare(b.name));
    })

    return grouped;
  }

  // Step 3: Convert to PrimeNG Tree component structure
  createTree(tree: { [key: string]: Group }): Observable<BoMTreeNode[]> {
    // This is all the groups
    const entries = Object.entries(tree);
    // Observables for each fitting group
    const fittingsNodesObservables = entries.map((entry) =>
      this.getFittingsData(this.createFittingsBody(entry[1])).pipe(
        map((res) =>
          res.bomFittings.map((fitting, fittingIndex) =>
            this.makeFittingPartTree(
              fitting,
              entry[0],
              entry[1].fittings.filter(
                (_fitting) => fitting.fittingCode === _fitting.codefitting || _fitting.codefitting.split(',').includes(fitting.fittingCode)
              )
            )
          )
        )
      )
    );

    if (fittingsNodesObservables.length === 0) {
      return of([]);
    }

    // Convert observables to a single one
    return forkJoin(fittingsNodesObservables).pipe(
      map((fittings) => {
        return entries.map((entry, index) => {
          return {
            key: entry[1].name,
            label: entry[1].name,
            data: { qty: entry[1].qty, name: entry[1].name, selected: 0, hovered: false },
            type: "instance",
            children: [
              ...(entry[1].parts.length > 0
                ? [
                    {
                      label: "Parts",
                      children: [
                        {
                          table: entry[1].parts.map((part) => {
                            const { name, ...partInfo } = part;
                            return {
                              label: name,
                              key: entry[1].name + "|" + part.name,
                              tableInfo: {
                                Quantity: partInfo.qty,
                                Material: partInfo.material,
                              },
                              data: {
                                object: part,
                                hovered: false,
                                selected: 0,
                              },
                            };
                          }),
                          type: "table",
                        },
                      ],
                      data: {selected: 0, hovered: false},
                      type: "instance",
                    },
                  ]
                : []),
              ...(fittings[index].length > 0
                ? [
                    {
                      label: "Fittings",
                      children: [
                        {
                          table: fittings[index],
                          type: "table",
                        },
                      ],
                      data: {selected: 0, hovered: false},
                      type: "instance",
                    },
                  ]
                : []),
            ],
          };
        });
      })
    );
  }

  // Step 4: Add parent to tree values. Recursive function that adds parent to tree items
  addParentsToTree(node: BoMTreeNode): void {
    if (node.children) {
      node.children.forEach((childNode) => {
        childNode.parent = node;
        this.addParentsToTree(childNode);
      });
    } else if (node["table"]) {
      (node as TableNode).table.forEach((childNode) => {
        childNode.parent = node;
        this.addParentsToTree(childNode);
      });
    }
  }

  /**
   * Returns the information of Fittings from fJS DB. Formats the information in a TreeNode structure
   * @param group group of parts by Room and InstanceNumber
   */
  createFittingsBody(group: Group): FittingsPayload {
    const formatFittings: FittingsPayload = { fittings: [] };
    group.fittings.map((fitting) => {
      formatFittings.fittings.push({
        partName: fitting.name,
        fittingCode: fitting.codefitting,
      });
    });
    return formatFittings;
  }

  getFittingsData(fittingsObj: FittingsPayload): Observable<FittingsResponse> {
    return this.http.post<FittingsResponse>("/api/fittings", fittingsObj);
  }


  expandOrCollapseBom(event: string){
    if(this.isolatedInstance === undefined){
      if(event === "collapse"){
        this.assemblyTree?.forEach((node) => this.recurseTreeDownards(node, undefined, undefined, false));
      }else if(event === "expand"){
        this.assemblyTree?.forEach((node) => this.recurseTreeDownards(node, undefined, undefined, true));
      }
    }else{
      this.recurseTreeDownards(this.isolatedInstance, undefined, undefined, true);
    }
   
  }
  makeFittingPartTree(
    fitting: BomFitting,
    namePart: string,
    original: PartOrFitting[]
  ) {
    const { name, ...partInfo } = this.makeFittingInfoTree(fitting);
    return {
      label: fitting.name,
      key: namePart + "|" + fitting.name,
      tableInfo: {
        "Quantity": partInfo.quantity,
        Configuration: partInfo.configuration,
        // 'Fitting Code': partInfo.fittingCode,
        // 'Part Name': partInfo.partName,
        "Supplier Link": partInfo.supplierLink,
      },
      data: {
        object: Object.assign({}, fitting, {
          occurences: original.reduce((a, b) => a.concat(b.occurences), []),
        }),
        hovered: false,
        selected: 0,
      },
    };
  }

  makeFittingInfoTree(fitting: BomFitting) {
    return {
      name: fitting.name,
      configuration: fitting.configuration,
      quantity: fitting.quantity,
      supplierLink: fitting.supplierLink,
      // partName: fitting.partName,
      // fittingCode: fitting.fittingCode,
      parentPartNames: fitting.parentPartNames,
    };
  }

  openSupplierLink(event: Event, url: string | undefined): void {
    event.stopPropagation();
    window.open("https://" + url);
  }

  /** forces page to run angular detect changes */
  detectChanges(): void {
    this.cd.detectChanges();
  }

  /**
   *
   * @returns GrainDirectionResponse: List of material Ids that are grain critical
   */
  getMaterialIds(): Observable<GrainDirectionResponse> {
    return this.http.post<GrainDirectionResponse>("/api/graindirection", {
      materialIds: [...new Set(this.materialIDs)],
    });
  }

  mapMaterialWithParts(
    groups: { [key: string]: Group },
    res: GrainDirectionResponse
  ):void {
    const grainMap: {  [key: string]: Vector3  } = { };
    Object.keys(groups).forEach((key) => {
      groups[key].parts.map((part) => {
        if (res.grainCriticalMaterialIds.indexOf(part.materialid) != -1) {
          part.occurences.forEach((occ) => {
            grainMap[occ[0]] = this.stringToVector3(part.graindirection);
          });
        }
      });
    });

    this.sceneManipulation.setGrainMap = grainMap;
  }

  stringToVector3(str: string): Vector3 {
    // Remove parentheses and spaces, then split the string by commas
    const values = str.replace(/[()]/g, "").split(",").map(Number);
    return new Vector3(values[0], values[1], values[2]);
  }
}

interface FittingsResponse {
  success: boolean;
  bomFittings: BomFitting[];
}

interface GrainDirectionResponse {
  success: boolean;
  grainCriticalMaterialIds: string[];
}

interface BomFitting {
  name: string;
  configuration: string[];
  quantity: number;
  supplierLink: string;
  notes?: string;
  partName: string;
  fittingCode: string;
  parentPartNames: string[];
}

interface FittingsPayload {
  fittings: {
    partName: string;
    fittingCode: string;
  }[];
}

interface RoomInstance {
  name: string;
  qty: number;
  type: string;
  items: TableRow[];
}

interface Group {
  name: string;
  qty: number;
  type: string;
  parts: PartOrFitting[];
  fittings: PartOrFitting[];
}

interface PartOrFitting {
  name: string;
  // codereference: string;
  qty: number;
  material: string;
  codefitting?: string;
  occurences: string[][];
  materialid: string;
  graindirection: string;
}

interface BoMTreeData {
  object?: PartOrFitting;
  hovered: boolean;
  selected: number;
  hide?: boolean;
  element?: HTMLDivElement;
}

type BoMTreeNode = InstanceTreeNode | TableNode | TableRowNode;

type InstanceTreeNode = TreeNode<BoMTreeData>;

export interface TableNode extends InstanceTreeNode {
  table?: TableRowNode[];
}

interface TableRowNode extends InstanceTreeNode {
  tableInfo?: {
    // 'Code Reference'?: string;
    Quantity?: number;
    Material?: string;
  };
}
