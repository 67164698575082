import { HttpClient, HttpContext, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Schedulers } from '@harmanpa/ng-cae';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    fromEvent,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    startWith,
    Subject,
    switchMap,
    timer
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ConnectivityService {

  constructor(private http: HttpClient, private zone: NgZone) {
  }

  public observeOnline(): Observable<boolean> {
    return merge(fromEvent(window, 'online'), fromEvent(window, 'offline')).pipe(
        map(_ => navigator.onLine),
        startWith(navigator.onLine),
        switchMap(navigatorOnline => {
          if (navigatorOnline) {
            return timer(0, 5000, Schedulers.outsideZone(this.zone)).pipe(
                mergeMap(_ => this.checkServer())
            );
          } else {
            return of(false);
          }
        }),
        distinctUntilChanged()
    );
  }

  public checkServer(): Observable<boolean> {
    return this.http.get<HttpResponse<any>>(
      '/api/ping',
      {
          context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
          observe: 'response'
      }).pipe(
      map(response => response.status === 200),
      catchError(_ => of(false))
    );
  }
}
