/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Artefact representing a file stored in database
 */
export interface Artefact { 
    /**
     * File name
     */
    name?: string;
    /**
     * File content-type
     */
    contentType?: string;
    /**
     * URI pointing to content
     */
    uri?: string;
    /**
     * SHA256 hash of content
     */
    hashString?: string;
}
