import {Component, OnInit} from '@angular/core';
import { Configurator, OnshapeAssembly, MATERIALFACTORY, MESHFACTORY, OnshapeConfiguratorService,
  OnshapeDocument } from '@harmanpa/ng-cae';
import { MaterialFactory } from '../../shared/services/material-factory.service';
import { MeshFactory } from '../../shared/services/mesh-factory.service';
import { CADConfiguration } from '@harmanpa/ng-cae/lib/types/cADConfiguration';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  providers: [
    { provide: MESHFACTORY, useClass: MeshFactory },
    {
      provide: MATERIALFACTORY,
      useClass: MaterialFactory
    },
  ],
})
export class ConfiguratorComponent implements OnInit {
  configurator: Configurator;
  documentId: string;
  wvmType: OnshapeDocument.WvmEnum;
  wvmId: string;
  elementId: string;

  configurator$: Observable<Configurator>;
  configuration: CADConfiguration;
  configuredAssembly: OnshapeAssembly = null;
  apiConfiguration: string;
  defaultConfiguration: CADConfiguration;
  appliedConfiguration: CADConfiguration;

  constructor(
    private configuratorService: OnshapeConfiguratorService,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('did')) {
      this.documentId = this.route.snapshot.paramMap.get('did');
      switch (this.route.snapshot.paramMap.get('wvm')) {
        case 'w':
          this.wvmType = 'Workspace';
          break;
        case 'v':
          this.wvmType = 'Version';
          break;
        case 'm':
          this.wvmType = 'Microversion';
          break;
      }
      this.wvmId = this.route.snapshot.paramMap.get('wvmid');
      this.elementId = this.route.snapshot.paramMap.get('eid');
      this.apiConfiguration = this.route.snapshot.queryParamMap.get('configuration');
    }

    this.configurator$ = this.configuratorService.getConfigurator(this.documentId,
      this.wvmType, this.wvmId, this.elementId);
    this.configurator$.subscribe({
      next: (configurator) => {
        this.configurator = configurator;
        this.defaultConfiguration = this.configuratorService
            .applyStringOverrideForDefaults(this.apiConfiguration,
                this.configuratorService.getDefaultConfiguration(configurator));
        this.configuration = _.cloneDeep(this.defaultConfiguration);
        this.appliedConfiguration = _.cloneDeep(this.defaultConfiguration);
        this.updateAssembly();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  updateAssembly(): void {
    console.log('Updating assembly');
    this.configuratorService.getAssembly(this.configurator, this.configuration)
      .subscribe({
        next: (configuredAssembly) => {
          this.configuredAssembly = configuredAssembly;
          this.appliedConfiguration = _.cloneDeep(this.configuration);
        },
        error: (err) => {
          console.log(err);
        }
      });
  }
}
