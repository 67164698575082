/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AssemblyOptions } from './assemblyOptions';
import { BoMTable } from './boMTable';
import { BoundingBox } from './boundingBox';
import { ConfiguredPart } from './configuredPart';
import { ConfiguredSubassembly } from './configuredSubassembly';
import { Mechanism } from './mechanism';

export interface ConfiguredAssembly { 
    documentId?: string;
    wvmType?: ConfiguredAssembly.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
    configuration?: string;
    subassemblies?: Array<ConfiguredSubassembly>;
    parts?: Array<ConfiguredPart>;
    boundingBox?: BoundingBox;
    bom?: BoMTable;
    mechanism?: Mechanism;
    options?: AssemblyOptions;
}
export namespace ConfiguredAssembly {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
