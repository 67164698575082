/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Reference to another object
 */
export interface Reference { 
    /**
     * Object type being referenced
     */
    type?: string;
    /**
     * Unique identifier of object
     */
    id?: string;
    /**
     * Version of object
     */
    version?: string;
}
