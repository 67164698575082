/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AbstractDocumentElement { 
    documentId?: string;
    wvmType?: AbstractDocumentElement.WvmTypeEnum;
    wvmId?: string;
    elementId?: string;
}
export namespace AbstractDocumentElement {
    export type WvmTypeEnum = 'Workspace' | 'Version' | 'Microversion';
    export const WvmTypeEnum = {
        Workspace: 'Workspace' as WvmTypeEnum,
        Version: 'Version' as WvmTypeEnum,
        Microversion: 'Microversion' as WvmTypeEnum
    };
}
