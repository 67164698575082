/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Company address
 */
export interface Address { 
    /**
     * Address line 1
     */
    address1?: string;
    /**
     * Address line 2
     */
    address2?: string;
    /**
     * City
     */
    city?: string;
    /**
     * State / County
     */
    state?: string;
    /**
     * Country
     */
    country?: string;
    /**
     * Postcode / ZIP
     */
    zip?: string;
}
