import { Component} from '@angular/core';

@Component({
  selector: 'app-logo-brand',
  templateUrl: './logo-brand.component.html',
})
export class LogoBrandComponent  {
  
  
}
