import { Injectable, NgZone } from "@angular/core";
import { AbstractObject3D, automaticUnsubscribe, OnshapeAssemblyComponent, OnshapePartDirective, OnshapeSubAssemblyComponent, RendererListener, RendererService, Schedulers, TableRow, View, ViewComponent } from "@harmanpa/ng-cae";
import { debounceTime, Observable, Subject, takeWhile } from "rxjs";
import { Box3, ConeGeometry, CylinderGeometry, Group, Material, Mesh, MeshBasicMaterial, Object3D, Raycaster, Scene, Vector2, Vector3 } from "three";

@Injectable({
    providedIn: 'root',
})
export class SceneManipulation implements RendererListener {
    /** Variable that is true when wall is extended  */
    private isWallExtended = false;
    /** Variable that is true when the objects are loaded */
    private areObjectsLoaded = false;
    /** Variable that is true when the camera is moving */
    private isCameraMoving = false;
    /** Contains the grainMaps */
    private grainMap: {  [key: string]: Vector3  } = {};
    /** Contains the grainMaps arrow object, the object that as it and the arrow points to check on the raycaster */
    private grainMapObjects: [Group, Mesh, Vector3[]][] = [];
    /** Contains the view */
    private view: ViewComponent;
    /** Raycaster to detect grain map intersections */
    private raycaster = new Raycaster();
    /** The size of the arrow according to the average size of the part (0.2 will be 20%) */
    private arrowPercentualSize = 0.5;
    /** Thickness of the line of the arrow */
    private arrowLineThickness = 0.003;
    /** The material for the grain arrows */
    private grainMaterial = new MeshBasicMaterial({ color: 0xffff00, depthTest: false, depthWrite: false });
    
    /** Variable that sets if parts are showing or only the fittings */
    isShowingParts = true;
    /** Contains the parts that are not fittings */
    normalParts: Mesh[] = [];
    /** Contains fittings */
    fittings: Mesh[] = [];
    /** Contains the surveys */
    surveys: Mesh[] = [];
    /** Contains all parts */
    allParts: Mesh[] = [];
    /** Subject that listens if we are isolating an instance */
    private isIsolatingInstance: Subject<boolean> = new Subject();
    /** Subject that will be triggered if there are fittings and the object is loaded */
    private hasFittings: Subject<void> = new Subject();
    /** Subject that will be triggered if there are grain maps and the object is loaded */
    private hasGrainMap: Subject<void> = new Subject();
    /** Subject that will be triggered when the objects are fully loaded */
    private hasObjectLoaded: Subject<void> = new Subject();

    constructor(private rendererService: RendererService, private ngZone: NgZone) {
        this.rendererService.addListener(this);
        this.raycaster.layers.disable(5);
    }

    setView(view: ViewComponent): void {
        this.view = view;

        //Listen for camera movement
        view.observeChanging().pipe(automaticUnsubscribe(this)).subscribe((observed) => {
            this.isCameraMoving = observed;
        });
    }

    onStartRendering(): void {}

    onRender(delta: number): void {
        //Only run code when grain map is activated and camera is moving
        if (this.grainMapObjects.length && this.isCameraMoving) {
            this.hideHiddenGrainMapArrows();
        }
    }

    onViewRender(view: View, delta: number): void {}

    observeHasFittings(): Observable<void> {
        return this.hasFittings;
    }

    observeHasGrainMap(): Observable<void> {
        return this.hasGrainMap;
    }

    observeHasObjectLoaded(): Observable<void> {
        return this.hasObjectLoaded;
    }

    observeIsolatingInstance(): Observable<boolean> {
        return this.isIsolatingInstance;
    }

    /**
     * Gets parts that are not fittings
     * @param rows - The rows from TableRow
     * @param assy - The onshape assembly
     */
    getParts(rows: TableRow[], assy: OnshapeAssemblyComponent): void {
        let fittingsFound = false;
        const normalPartIds: string[] = [];
        rows.forEach((row) => {
            if (row.data.category === "FJS Part" || row.data.fittingcode === "N/A" || !row.data.fittingcode) {
                normalPartIds.push(row.itemSource.partId);
            } else {
                fittingsFound = true;
            }
        });

        assy.observeChange().pipe(automaticUnsubscribe(this), debounceTime(200)).subscribe(() => {
            this.areObjectsLoaded = true;
            this.normalParts = [];
            this.fittings = [];
            this.surveys = [];
            this.getAssemblies(assy, normalPartIds);
            this.allParts = this.normalParts.concat(this.fittings.concat(this.surveys));

            if (this.areObjectsLoaded) {
                if (Object.keys(this.grainMap).length) {    //If there is already a grain map, enable grain buttons
                    this.hasGrainMap.next();
                }
    
                if (fittingsFound) {
                    this.hasFittings.next();
                }

                this.hasObjectLoaded.next();
            }
        });
    }

    /**
     * Loops through onshape assembly to get meshes
     * @param onShapeAssembly - the onshape assembly
     * @param normalPartIds - the part ids that are not fittings
     */
    private getAssemblies(
        onShapeAssembly: OnshapeAssemblyComponent | OnshapeSubAssemblyComponent,
        normalPartIds: string[]
    ): void {
        onShapeAssembly
            .getChildNodes()
            .forEach((child: OnshapePartDirective | OnshapeSubAssemblyComponent) => {
                if (child instanceof OnshapePartDirective) {
                    const childObjects = child.getCurrentObjectAsArray();
                    if (childObjects) {
                        if (normalPartIds.includes(child.part.partId)) {
                            if (child.part.name.startsWith("Survey")) {
                                childObjects?.forEach((_child) => {
                                    _child.layers.set(3);  //If survey, change layer for surveys to not be selectable
                                    this.surveys.push(_child);
                                });
                            } else {
                                childObjects?.forEach((_child) => {
                                    this.normalParts.push(_child);
                                });
                            }
                        } else {
                            childObjects?.forEach((_child) => {
                                this.fittings.push(_child);
                            });
                        }
                    } else {    //If no objects than it means the object wasnt fully loaded
                        this.areObjectsLoaded = false;
                    }
                } else {
                    this.getAssemblies(child, normalPartIds);
                }
            });
    }

    /**
     * Hides or shows Parts (to show only fitting)
     * @param assembly - the onshape assembly
     * @param show - if its to hide or show
     */
    hideShowParts(assembly: OnshapeAssemblyComponent, show: boolean): void {
        this.loopToggleParts(assembly, show);
    }

    /**
     * Loops through onshape assembly to toggle the parts (changes opacity to them)
     * @param onShapeAssembly - the onshape assembly
     * @param show - if its to show or hide
     */
    private loopToggleParts(
        onShapeAssembly: OnshapeAssemblyComponent | OnshapeSubAssemblyComponent,
        show: boolean
    ): void {
        onShapeAssembly
            .getChildNodes()
            .forEach((child: OnshapePartDirective | OnshapeSubAssemblyComponent) => {
                if (child instanceof OnshapePartDirective) {
                    const childObjects = child.getCurrentObjectAsArray();

                    if (childObjects.find((_child) => this.normalParts.includes(_child))) {
                        if (show) {                            
                            childObjects?.forEach((mesh) => {
                                (mesh.material as Material).transparent = false;
                                (mesh.material as Material).opacity = 1;
                                (mesh.material as Material).vertexColors = true;
                                (mesh.material as Material).needsUpdate = true;
                                mesh.layers.set(0);  //Reset layer for mesh to be selectable
                            });
                        } else {  //Reset object
                            childObjects?.forEach((mesh) => {
                                (mesh.material as Material).transparent = true;
                                (mesh.material as Material).opacity = 0.2;
                                (mesh.material as Material).vertexColors = true;
                                (mesh.material as Material).needsUpdate = true;
                                mesh.layers.set(5);  //Set layer 5 for mesh not to be selectable while measure works properly
                            });
                        }
                    }
                } else {
                    this.loopToggleParts(child, show);
                }
            });
    }

    /**
     * Extends wall to make the drawers visible (NOT BEING USED)
     * @param assy - the onshape assembly
     */
    extendWall(assy: OnshapeAssemblyComponent): void {
        assy.observeBounds(200, true, true, 1e-6, Schedulers.outsideZone(this.ngZone))
            .pipe(
                automaticUnsubscribe(this), takeWhile(() => !this.isWallExtended)
            ).subscribe((bounds) => {
            const walls = assy?.getChildNodes()?.find(object => (object as any).part.name.startsWith("Survey"));
            const object = walls?.getObject();
            if (object && object.children[0]?.["isMesh"] === true) {
                object.children[0].scale.set(1.004, 1.004, 1.004);
                this.isWallExtended = true;
            }
        });
    }

    set setGrainMap(grainMap: {  [key: string]: Vector3  }) {
        if (this.areObjectsLoaded) {    //If objects are loaded, enable grain button
            this.hasGrainMap.next();
        }
        this.grainMap = grainMap;
    }

    /**
     * Hides or shows grain maps arrows
     * @param show - if its to hide or show
     */
    hideShowGrainMap(show: boolean): void {
        if (show) { //Create grain arrows
            //Ghost all parts
            this.normalParts.concat(this.fittings).forEach((mesh) => {
                mesh.layers.set(5); //MAke objects not selectable
                (mesh.material as Material).opacity = 0.2;
                (mesh.material as Material).transparent = true;
                const line = mesh.children.filter(child => child.type === "LineSegments2");
                if (line) {
                    line.forEach((child: Mesh) => {
                        (child.material as Material).opacity = 0.2;
                        (child.material as Material).transparent = true;
                    });
                }
            });

            Object.keys(this.grainMap).forEach((occ, index) => {
                const object = this.view.sceneComponent.findByPath([occ]);
                if (!object.isHelper()) {
                    //Unghost parts with grainmaps
                    const mesh = (object.getObject()?.children?.[0] as Mesh);
                    if (mesh?.material) {
                        mesh.layers.set(0);  //Reset layer for mesh to be selectable
                        (mesh.material as Material).opacity = 1;
                        (mesh.material as Material).transparent = false;

                        const line = mesh.children.filter(child => child.type === "LineSegments2");
                        if (line) {
                            line.forEach((child: Mesh) => {
                                (child.material as Material).opacity = 1;
                                (child.material as Material).transparent = false;
                            });
                        }
                    }

                    this.createGrainMapArrow(object, this.grainMap[occ], this.view.sceneComponent.scene);
                    this.hideHiddenGrainMapArrows();
                }
            });
        } else {
            this.view.sceneComponent.scene.remove(...this.grainMapObjects.map((mapObj) => mapObj[0]));
            this.grainMapObjects = [];
            this.normalParts.concat(this.fittings).forEach((mesh) => {  //Reset layers
                mesh.layers.set(0);
            });
        }
    }
    
    /**
     * Creates a grain map arrow
     * @param object - the abstract object that contains the grain map
     * @param direction - the direction of the grain map
     * @param scene - the scene
     */
    private createGrainMapArrow(object: AbstractObject3D<Object3D>, direction: Vector3, scene: Scene): void {
        direction = new Vector3(direction.x, direction.z, -direction.y);

        //Get object bounding box
        let box = new Box3();
        object.getBounds(box, true);
        box = new Box3(new Vector3(box.min.x, -box.max.z, box.min.y), new Vector3(box.max.x, -box.min.z, box.max.y));

        //Get size and center
        let size = new Vector3();
        let center = new Vector3();
        box.getSize(size);
        box.getCenter(center);

        // Get direction and main direction
        const normalizedDirection = direction.clone().normalize();
        const absDirection = new Vector3(Math.abs(normalizedDirection.x), Math.abs(normalizedDirection.y), Math.abs(normalizedDirection.z));
        normalizedDirection.multiplyScalar(10000);

        const group = new Group();

        const arrowPosition = new Vector3(center.x, center.y, center.z);
        const avgSize = (size.x + size.y + size.z) / 3;
        const arrowSize = avgSize * this.arrowPercentualSize;

        // Create the cylinder
        const cilynderGeometry = new CylinderGeometry(this.arrowLineThickness, this.arrowLineThickness, arrowSize * 0.75, 32);
        const cilynder = new Mesh(cilynderGeometry, this.grainMaterial);

        // Flatten cylinder
        group.add(cilynder);

        // Create the cone
        const coneGeometry = new ConeGeometry(Math.max(arrowSize * 0.05, 0.005), arrowSize * 0.125, 32);
        const cone = new Mesh(coneGeometry, this.grainMaterial);

        // Flatten cone
        cone.translateY(avgSize * this.arrowPercentualSize * 0.425);
        group.add(cone);

        // Create second cone
        const cone2 = new Mesh(coneGeometry.clone(), this.grainMaterial);

        // Flatten cone
        cone2.translateY(-avgSize * this.arrowPercentualSize * 0.425);
        cone2.rotateZ(Math.PI);
        group.add(cone2);
        scene.add(group);

        group.position.copy(arrowPosition);
        group.lookAt(arrowPosition.clone().add(normalizedDirection.clone().multiplyScalar(avgSize)));
        group.rotateX(Math.PI / 2);
        const minSize = Math.min(size.x, size.y, size.z);

        group.renderOrder = 99;
        group.name = "arrows";

        // Get dominant axis
        if (absDirection.x >= absDirection.y && absDirection.x >= absDirection.z) { //X
            if (minSize === size.x || minSize === size.z) {
                group.rotateY(Math.PI / 2);
            }

            group.scale.set(0.01, 1, 1);
        } else if (absDirection.y >= absDirection.x && absDirection.y >= absDirection.z) {  //Y
            if (minSize === size.x) {
                group.rotateY(Math.PI / 2);
            }

            group.scale.set(1, 1, 0.01);
        } else {  //Z
            if (minSize === size.y) {
                group.rotateY(Math.PI / 2);
            } else if (minSize === size.z) {
                group.rotateY(Math.PI / 2);
            }

            group.scale.set(0.01, 1, 1);
        }

        //Get bounding box of arrow
        const arrowBox = new Box3().setFromObject(group);
        const axesSize = arrowBox.getSize(new Vector3());
        const maxSize = Math.max(axesSize.x, axesSize.y, axesSize.z);
        const axesCenter = arrowBox.getCenter(new Vector3());

        let corners: Vector3[];
        if (maxSize === axesSize.x) {
            corners = [
                new Vector3(arrowBox.min.x, axesCenter.y, axesCenter.z),
                new Vector3(arrowBox.max.x, axesCenter.y, axesCenter.z),
            ];
        } else if (maxSize === axesSize.y) {
            corners = [
                new Vector3(axesCenter.x, arrowBox.min.y, axesCenter.z),
                new Vector3(axesCenter.x, arrowBox.max.y, axesCenter.z),
            ];
        } else {
            corners = [
                new Vector3(axesCenter.x, axesCenter.y, arrowBox.min.z),
                new Vector3(axesCenter.x, axesCenter.y, arrowBox.max.z),
            ]
        }
        this.grainMapObjects.push([group, object.getObject().children[0] as Mesh, corners]);
    }

    /**
     * Function that uses the raycaster to hide/show the grainMap arrows that are visible or not
     */
    private hideHiddenGrainMapArrows() {
        this.grainMapObjects.forEach(group => {
            const camera = this.view.view.camera.getActiveCamera();
            let isVisible = false;

            for (let i = 0; i < group[2].length; i++) {
                //Get the screen coord of point
                const screenCoords = group[2][i].clone().project(camera);
                
                //Check intersection
                this.raycaster.setFromCamera(screenCoords as any as Vector2, camera);
                const intersects = this.raycaster.intersectObjects(this.allParts);

                // If the first intersection is the object group[1], mark it as visible
                if (!intersects[0] || intersects[0].object === group[1]) {
                    isVisible = true;
                    break;
                }
            }
           
            group[0].visible = isVisible;
        });
    }

    /**
     * Isolates an instance by setting as helpers all the other parts
     * @param occurences 
     */
    isolateInstance(occurences: string[][]): void {    
        //Make all parts invisible, as an helper (for the camera zoom to fit to ignore them), and layer 3 for measure to ignore them 
        this.allParts.forEach((part) => {
            part.visible = false;

            const abstractPart = this.view.sceneComponent.find(part, false);
            abstractPart.isHelper = () => {
                return true;
            }

            part.layers.set(3);
        });

        // Get parts from occurences
        const parts = occurences
            .map((occ) => this.view.sceneComponent.findByPath(occ));

        //Reset hiding from parts that are being isolated
        parts.forEach((part: OnshapePartDirective) => {
            part.isHelper = () => {
                return false;
            }
            const arrParts = part.getCurrentObjectAsArray();
            arrParts.forEach((_part) => {
                _part.visible = true;
                _part.layers.set(0);
            });
        });

        this.isIsolatingInstance.next(true);
    }

    /**
     * Connects an instance by making all parts visible again
     */
    connectInstance(): void {
        this.allParts.forEach((part) => {
            part.visible = true;
            const abstractPart = (this.view.sceneComponent.find(part, false) as OnshapePartDirective);
            if (!abstractPart.part.name.startsWith("Survey")) {
                part.layers.set(0);
            }
            abstractPart.isHelper = () => {
                return false;
            }
        });

        this.isIsolatingInstance.next(false);
    }

    /**
     * When material type is changed and we are isolating an instance, we need to disable the 4 layer again
     * to prevent the selection-manager raycaster from detecting it, otherwise the project gets very slow for some reason
     * (probably a line2 bug).
     * We also need to set the part layer to 3 again for the measure to ignore them
     */
    updateLinesIsolating(): void {
        this.allParts.forEach((part) => {
            if (part.visible === false) {   //For the parts that are invisible
                //Need to disable the the lines layer 4 for raycaster to not try to get these while the object is visible (makes project very slow for some reason)
                const line = part.children.filter(child => child.type === "LineSegments2");
                if (line) {
                    line.forEach(element => {
                        element.layers.disable(4);
                    });
                }
                part.layers.set(3); // Set layers again to 3 for measures to ignore them
            }
        });
    }

    /**
     * Hides the surveys
     */
    hideSurveys(): void { 
        this.surveys.forEach((survey) => {
            survey.visible = false;
        });
    }

    /**
     * Shows the surveys
     */
    showSurveys(): void { 
        this.surveys.forEach((survey) => {
            survey.visible = true;
        });
    }
}