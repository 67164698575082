<div class="logo-brand flex align-items-center p-2 sm:p-2 md:p-2 lg:p-3" [style]="{'position': 'absolute',
    'background':'#fff',
    'z-index':'2',
    'color':'#bdbdbd'}">
  <img
    alt="Future Joinery Systems"
    src="assets/Logo Stroke-01.svg"
    class="mr-1 sm:mr-1 md:mr-2 lg:mr-3 logo-img"
  />
  <p class="p-0 m-0 font-bold dark-grey text-xs sm:text-xs md:text-md lg:text-lg">Future Joinery Systems</p>
</div>

