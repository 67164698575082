import {Directive, ElementRef, Host, Input, NgZone, OnDestroy, Optional} from '@angular/core';
import { BillOfMaterialsComponent } from '../bill-of-materials/bill-of-materials.component';

@Directive({
  selector: '[nodeTree]'
})
export class NodeTreeDirective implements OnDestroy {
    /** The node value */
    @Input() node;

    //Variables that will have the event listeners
    pointerEnterListener: (e: PointerEvent) => void;
    pointerLeaveListener: (e: PointerEvent) => void;

    constructor(@Host() @Optional() private parent: BillOfMaterialsComponent, private el: ElementRef, private ngZone: NgZone) { }

    ngOnInit(): void {
        this.node.data.element = this.el.nativeElement;

        this.ngZone.runOutsideAngular(() => {
            this.pointerEnterListener = (e: PointerEvent) => this.parent?.hoverRowTable(e, this.node);
            this.pointerLeaveListener = (e: PointerEvent) => this.parent?.unHover();

            //Listen for pointerenter/pointerleave events to hover/unhover
            this.el.nativeElement.addEventListener('pointerenter', this.pointerEnterListener);
            this.el.nativeElement.addEventListener('pointerleave', this.pointerLeaveListener);
        });
    }

    ngOnDestroy(): void {
        //On destroy, remove element and event listeners
        delete this.node.data.element;
        this.el.nativeElement.removeEventListener('pointerenter', this.pointerEnterListener);
        this.el.nativeElement.removeEventListener('pointerleave', this.pointerLeaveListener);
    }
}
