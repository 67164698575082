/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Version history of object
 */
export interface VersionGraphVersion { 
    /**
     * Version identifier
     */
    version?: string;
    /**
     * Previous versions
     */
    parents?: Array<string>;
    tags?: Array<string>;
    meta?: { [key: string]: any; };
    /**
     * Tasks not yet completed for this version
     */
    pending?: Array<string>;
    /**
     * Failed tasks for this version
     */
    failed?: Array<string>;
    /**
     * Author of version
     */
    author?: string;
    /**
     * Date version created
     */
    created?: Date;
}
