/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Information about application version
 */
export interface AppVersion { 
    /**
     * Base URL of application
     */
    home?: string;
    /**
     * Application version
     */
    app?: string;
    /**
     * Patchwork library version
     */
    pwk?: string;
}
