import { ConfiguredPart, PartMetaData } from "../../../../generated-src";
import * as THREE from "three";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { BaseMaterialFactory, MaterialStyling, MaterialType, Part } from "@harmanpa/ng-cae";
import { Color, Material } from "three";

@Injectable()
export class MaterialFactory extends BaseMaterialFactory<ConfiguredPart> {
  textureLoader = new THREE.TextureLoader();

  constructor() {
    super();
  }

  createMaterialColored(part: Part & {
    name?: string;
  }, color?: Color, opacity?: number, meta?: PartMetaData, type?: MaterialType, materialStyling?: MaterialStyling, materialSent?: Material): Material | Observable<Material> {
      materialStyling = {
        type: 'Standard',
        metalness: 0,
        roughness: 1
      };
      
      const m = super.createMaterialColored(part, color, opacity, meta, type, materialStyling, materialSent) as Material;
      m.vertexColors = true;
      return m;
  }

  editMeshRenderType(
    part: Part & { name: string },
    type: MaterialType,
    material: Material,
    materialStyling?: MaterialStyling
  ): Material {
    const m = super.editMeshRenderType(part, type, material, materialStyling);
    if (type === MaterialType.Wireframe) {
      m.visible = true;
      m.opacity = 0;
      m.transparent = true;
    }
    return m;
  }
}
