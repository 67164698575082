<div (click)="stopProp($event)" class="align-items-center flex search-bar">
    <div class="p-input-icon-left w-full border-radius-md">
      <i class="pi pi-search pl-1 pr-3"></i>
      <input
        type="text"
        class="h-full"
        pInputText
        placeholder="Search"
        style="width:100%"
        [(ngModel)]="query"
        (ngModelChange)="filterData()"
      />
      <button
        pButton
        pRipple
        *ngIf="query"
        icon="fas fa-xmark"
        class="p-button-text search-clear"
        (click)="clearQuery()"
      ></button>
    </div>
  </div>
  