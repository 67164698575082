<div class="bomPanel flex flex-column" [class.mobile]="mobile" [class.overflow-y-scroll]="mobile">
  <p class="px-2 sm:px-2 md:px-3 lg:px-6 pt-3 sm:pt-3 md:pt-3 lg:pt-6 sm:text-sm md:text-md lg:text-lg font-bold">{{projectName}}</p>
  <div class="pt-0 sm:pt-0 md:pt-2 lg:pt-3 px-2 sm:px-2 md:px-3 lg:px-6" [class.padding-bottom]="!description">
    <app-document-version [online]="online" [configurator]="configurator"></app-document-version>
  </div>
  <div class="px-2 sm:px-2 md:px-3 lg:px-6 py-3 sm:py-3 md:py-3 lg:py-5 arvo-font responsive-text-body" *ngIf="description && !loading">
    <span class="font-bold responsive-text-body">Notes:</span> {{description}}
  </div>
  <div class="flex flex-column gap-2 sm:gap-2 md:gap-2 lg:gap-5 px-2 sm:px-2 md:px-3 lg:px-6 flex-1" [class.overflow-hidden]="!mobile" *ngIf="assemblyTree && assembly && !loading">
    <div class="flex gap-2">
      <app-search-bar #searchBar class="flex-1" [data]="assemblyTree" (expandOrCollapseChange)="expandOrCollapseBom($event)"></app-search-bar>
      <span class="p-buttonset fjs-buttonset">
        <button pButton class="buttonset-left" icon="pi pi-angle-double-right" tooltipPosition="top" pTooltip="collapse" (click)="expandOrCollapseBom('collapse')"></button>
        <button pButton class="buttonset-right" icon="pi pi-angle-double-down" tooltipPosition="top" pTooltip="expand" (click)="expandOrCollapseBom('expand')"></button>
      </span>
    </div>
    <p-tree
      #treeElem 
      [value]="assemblyTree" 
      (onNodeCollapse)="detectChanges()" 
      (onNodeExpand)="detectChanges()" 
      class="overflow-y-auto mb-3 select-none border-round-md">
      <ng-template let-node pTemplate="instance">
        <div 
          nodeTree
          [node]="node"
          [class.non-isolated]="isolatedInstance && (node !== isolatedInstance && node.parent !== isolatedInstance)" 
          [class.selectedRow]="node.data?.selected" 
          [class.hoveredRow]="node.data?.hovered" 
          [class.sub-parent-node]="node.parent"
          [class.parent-node]="!node.parent"
          [class.hide-parent-node]="node.data.hide"
          class="flex align-items-center py-2"
          (click)="selectRowTable($event, node)">
          <p 
            class="node-label montserrat-font font-bold m-0 responsive-text-body"
            [class.w-30]="!node.parent"
            [class.mb-0]="!node.parent">{{ node.label }}</p>
          <ng-container *ngIf="!node.parent">
            <div class="flex ml-auto">
              <p-badge *ngIf="node.data?.selected" [value]="'x' + node.data?.selected" styleClass="border-round-3xl node-badge" class="mr-2"></p-badge>
              <div class="flex flex-wrap ml-auto column-gap-2">
                <button *ngIf="node !== isolatedInstance"
                  (click)="isolateInstance(node, $event)"
                  class="fjs-button py-2 min-w-min"
                  icon="pi pi-plus"
                  pButton
                  pRipple
                  type="button"
                ></button>
                <button
                  *ngIf="node === isolatedInstance"
                  (click)="connectInstance($event)"
                  class="fjs-button close-instance-btn"
                  icon="pi pi-times"
                  pButton
                  pRipple
                  type="button"
                ></button>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template let-node pTemplate="table">
        <p-table [value]="node.table" class="block" styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
          <ng-template pTemplate="header">
              <tr class="arvo-font responsive-text-body">
                <th>Name</th>
                <th *ngFor="let item of node.table[0]?.tableInfo | keyvalue">{{item.key}}</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-node class="arvo-font responsive-text-body">
              <tr 
                nodeTree
                [node]="node"
                [class.hidden]="node.data.hide"
                [ngClass]="{hoveredRow: node.data.hovered, selectedRow: node.data.selected}"
                (click)="selectRowTable($event, node)">
                <td class="arvo-font responsive-text-body">
                  {{ node.label }}
                </td>
                <td class="arvo-font responsive-text-body" *ngFor="let item of node.tableInfo | keyvalue">
                  <p *ngIf="item.key !=='Configuration'  &&  item.key !== 'Supplier Link' &&  item.key !== 'Quantity'">{{item.value}}</p>
                  <p *ngIf="item.key ==='Quantity'"><span *ngIf="node.data.selected" 
                    [class.tag-qty]="node.data.selected < item.value">{{node.data.selected}} of</span> 
                    {{item.value}}</p>
                  <p *ngIf="item.key ==='Configuration'" [innerHTML]="item.value|replaceCommasWithNewlines"></p>
                  <button
                    *ngIf="item.key === 'Supplier Link' && item.value"
                    (click)="openSupplierLink($event, item.value)" target="_blank"
                    class="fjs-button pointer-events-auto"
                    icon="pi pi-external-link"
                    pButton
                    pRipple
                    type="button"
                  ></button>
                </td>
              </tr>
          </ng-template>
        </p-table>
      </ng-template>
    </p-tree>
  </div>
  <div *ngIf="!assemblyTree || loading" class="flex flex-column justify-content-center align-items-center flex-1">
    <img
      alt="Future Joinery Systems"
      src="assets/Logo Stroke-01.svg"
      class="w-2rem h-2rem animate-bounce"
    />
    <p class="text-center mt-3 text-sm sm:text-xs md:text-sm lg:text-md">Loading...</p>
  </div>
</div>
