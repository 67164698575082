/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Model of access permissions for given object
 */
export interface Permissions { 
    /**
     * The document workspace
     */
    workspace?: string;
    /**
     * This document is considered to be public
     */
    publicDocument?: boolean;
}
