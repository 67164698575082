import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TableNode } from '../bill-of-materials/bill-of-materials.component';
import * as _ from 'lodash-es';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
})
export class SearchBarComponent {
  query = '';
  @Input() data: TreeNode<any>[];
  @Output() expandOrCollapseChange = new EventEmitter<string>();
  constructor() {}

  /**
   * Filters data according to the query
   */
  filterData(): void {
    const query = this.query.toLowerCase();

    this.data.forEach(element => {
      if(query.length == 0){
        this.clearQuery();
        return;
      };
      
      if (element.key.toLowerCase() === query) {  //If query matches exactly the instance, show all its childs
        this.resetFilterData(element);
      } else if (element.children) {
        let found = false;

        //filter Children (fittings|parts|parts by others)
        element.children.forEach(child => {
          let tableFound = false;
          const tableNode = child.children[0] as TableNode;

          //Filter table
          tableNode.table.forEach(_child => {
            if (_child.key.toLowerCase().includes(query) || _child.label.toLowerCase().includes(query)) {
              tableFound = true;
              _child.data.hide = false;
            } else {
              _child.data.hide = true;
            }
          });

          // If found any match on the table
          if (tableFound) {
            found = true;
            child.data.hide = false;
          } else {
            child.data.hide = true;
          }
        });

        if (found) {
          element.data.hide = false;
          //something has been found so empty state is false
        } else {
          element.data.hide = true;
        }
      } else {
        element.data.hide = true;
      }
      this.expandOrCollapseChange.emit('expand');  //Emit expand or collapse event based on current state
    });
  }

  /**
   * Clears the query
   */
  clearQuery(): void {
    this.query = '';
    this.data.forEach(element => this.resetFilterData(element));  //Set all instances visible
    this.expandOrCollapseChange.emit('collapse');
  }

  /**
   * Stops propagation
   * @param event 
   */
  stopProp(event: Event): void {
    event.stopPropagation();
  }

  /**
   * Resets data to visible
   * @param element
   */
  resetFilterData(element: TreeNode): void {
     element.data.hide = false;
     element.children.forEach(child => {
       const tableNode = child.children[0] as TableNode;
       tableNode.table.forEach(_child => {
         _child.data.hide = false;
       });
       child.data.hide = false;
     });
     this.expandOrCollapseChange.emit('collapse');
  }
}
