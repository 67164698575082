/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Browser used
 */
export interface Browser { 
    /**
     * Browser family
     */
    family?: string;
    /**
     * Browser major version
     */
    major?: string;
    /**
     * Browser minor version
     */
    minor?: string;
    /**
     * Browser patch version
     */
    patch?: string;
}
