<div class="flex gap-2 document-version-comp" >
    <div class="w-6">
        <p class="arvo-font mb-1 responsive-text-title">Version</p>
        <p-dropdown
            placeholder="Version"
            class="w-12 flex responsive-text-body"
            [options]="versionNames"
            [ngModel]="currentVersionName"
            [disabled]="!configurator || versionNames.length===0 || !online"
            (ngModelChange)="updateModel($event)"
            [appendTo]="'body'"
            />
    </div>
    <div class="w-6">
        <p class="arvo-font mb-1 responsive-text-title">Room</p>
         <p-dropdown
            class="w-12 flex responsive-text-body"
            placeholder="Element"
            [options]="elementNames"
            [ngModel]="currentElementName"
            [disabled]="!configurator || elementNames.length===0 || !online"
            (ngModelChange)="updateElement($event)"
            [appendTo]="'body'"
            />
    </div>
</div>