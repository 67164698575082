import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularSplitModule } from 'angular-split';
import { UiModule } from './ui/ui.module';
import { AdminRoutingModule, AuthModule, DeveloperRoutingModule, GleapToken,
  NgCaeModule, NgPatchworkComponent, PatchworkAPI, UserflowRoutingModule } from '@harmanpa/ng-cae';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { HttpClientModule } from '@angular/common/http';
import { ApiModule, DefaultService } from 'generated-src';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReplaceCommasWithNewlinesPipe } from './shared/pipes/noCommas.pipe';

export function windowFactory(): Window {
  return window;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    NgCaeModule.forRoot({
      userflow: {
        enableSignup: true,
        roleLogin: {
          superadmin: {
            enablePassword: true,
            providerLogin: { google: { name: 'Admin' } },
          },
        },
        login: {
          enablePassword: true,
          providerLogin: {},
        },
        leftPanel: {
          title: 'Products with limited options belong to the past',
          subtitle: 'This is a copy text for the dummy design',
        },
        rightPanel: {
          title: 'Welcome back',
          subtitle: 'Welcome back! Please enter your details.',
        },
      },
      types: {
      },
      units: [],
    }),
    UserflowRoutingModule,
    AdminRoutingModule,
    AngularSplitModule.forRoot(),
    ApiModule,
    AppRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    DeveloperRoutingModule,
    FormsModule,
    HttpClientModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    NgxPermissionsModule.forRoot(),
    NgPatchworkComponent,
    AuthModule.forRoot({
      baseUrl: '/api'}),
    NgCaeModule,
    UiModule,
    ReplaceCommasWithNewlinesPipe 
  ],
  providers: [
    { provide: PatchworkAPI, useExisting: DefaultService },
    { provide: 'window', useFactory: windowFactory },
    { provide: GleapToken, useValue: 'zpq9CPCZv5xSfhJd2AOcSglR28TB8B6b' },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {

}
