<cae-toolframe
  class="configurator"
  [marginTop]="0"
  [marginBottom]="0"
  position="Fullscreen"
  [leftPanelVisible]="false"
  [rightPanelVisible]="true"
  [rightPanelSize]="35"
  [rightPanelMinSize]="25"
  [rightPanelMaxSize]="50"
>
  <cae-tool-panel placement="Left">
      <app-logo-brand></app-logo-brand>
  </cae-tool-panel>
  <cae-tool-panel placement="Right">
    <app-bill-of-materials
      [mobile]="mobileDevice"
      [online]="isOnline"
      [configurator]="configurator"
      [assembly]="configuredAssembly"
      [description]="description"
      [view]="mainView"
      (clickedObjects)="setClickedObjects($event)"
    ></app-bill-of-materials>
  </cae-tool-panel>
  <cae-views-container [columns]="6" [rows]="3">
    <cae-view
      #mainView
      [gridHeight]="3"
      [near]="0.0001"
      [gridWidth]="6"
      [gridColumnStart]="1"
      [gridRowStart]="1"
      [position]="specPosition"
      [homeVector]="specHomeVector"
      [raycasterExtraLayers]="[4]"
    >
      <cae-camera-controls
        #cameraControlsComponent
        [zoomToFitMax]="0.9"
        [zoomSpeed]="6"
        [zoomRelativeToMouse]="true"
        [layers]="[3, 5]"
        [orbitHomeOnCameraChange]="true"
        [minMaxConsiderParts]="true"
      >
      </cae-camera-controls>
      <cae-scene #scene [rotateX]="minushalfpi" [background]="sceneBackground">
        <!-- Top Light -->
        <cae-directional-light
          [color]="white"
          [view]="mainView"
          [intensity]="1"
          [translateZ]="1"
          [translateY]="0"
          [translateX]="0"
          [rotateX]="halfpi"
          [castShadow]="false"
        ></cae-directional-light>
        <!-- Left Light -->
        <cae-directional-light
          [color]="white"
          [view]="mainView"
          [intensity]="1"
          [translateZ]="0"
          [translateY]="0"
          [translateX]="-1"
          [rotateX]="halfpi"
          [castShadow]="false"
        ></cae-directional-light>
        <!-- Right Light -->
        <cae-directional-light
          [color]="white"
          [view]="mainView"
          [intensity]="1"
          [translateZ]="0"
          [translateY]="0"
          [translateX]="1"
          [rotateX]="halfpi"
          [castShadow]="false"
        ></cae-directional-light>
        <cae-ambient-light
          [color]="white"
          [intensity]="1.75"
        ></cae-ambient-light>
        <cae-onshape-assembly
          #assy
          [configuredAssembly]="configuredAssembly"
        ></cae-onshape-assembly>
        <cae-measure
          [view]="mainView" 
          [targets]="clickedObjects?.length ? clickedObjects : [assy]" 
          [isArrow]="false" 
          measureUnit="mm"
          fractionDigits="1"
          [extraPaddingArrow]="0.01"
          [arrowMaterial]="arrowMaterial"
          [arrowMaterialHidden]="arrowMaterialHidden"
          [ignoreLayers]="[3]"
          [safeMeasures]="false"
        ></cae-measure>
      </cae-scene>
      <app-version [version]="appVersion"></app-version>
      <div class="toolbar-parent w-full absolute z-2 justify-content-center flex flex-wrap">
        <cae-toolbar
          [class.disable-toolbar-button]="!objectsLoaded"
          [assembly]="configuredAssembly"
          [controls]="cameraControlsComponent"
          [showHelp]="false"
          [showScreenModelToolbar]="true"
          [includeRenderTypes]="true"
          [includeClipping]="false"
          [includeMeasure]="true"
          [includeExploded]="isIsolatingInstance ? true : 'instant'"
          [maxExplodeLevel]="isIsolatingInstance ? 3 : 2"
          [minExplodeLevel]="isIsolatingInstance ? 2 : 0"
          [includeMultipleViews]="false"
          [includeAnnotations]="false"
          [closeOnUnfocus]="true"
          [showIsometric]="false"
          [renderTypes]="renderTypes"
        >
        </cae-toolbar>
         <div class="flex toolbar-element">
          <!-- Toogle parts toolbar -->
          <div class="p-buttonset">
            <button
              *ngIf="isShowingParts"
              pTooltip="Isolate Fittings"
              tooltipPosition="top"
              pButton
              icon="pi pi-eye-slash"
              (click)="toggleParts($event)"
              [disabled]="!hasIsolateFittings"
            ></button>
            <button
              *ngIf="!isShowingParts"
              pTooltip="Show Parts"
              tooltipPosition="top"
              pButton
              icon="pi pi-eye"
              (click)="toggleParts($event)"
              [disabled]="!hasIsolateFittings"
            ></button>
          </div>
          <!-- Toogle grain direction -->
          <div class="p-buttonset">
            <button
              *ngIf="isShowingGrain"
              pTooltip="Hide Grain Direction"
              tooltipPosition="top"
              pButton
              (click)="toggleGrain($event)"
              [disabled]="!hasGrainMaps"
              icon="fa-light fa-arrow-down-up-across-line">
            </button>
            <button
              *ngIf="!isShowingGrain"
              pTooltip="Show Grain Direction"
              tooltipPosition="top"
              pButton
              (click)="toggleGrain($event)"
              [disabled]="!hasGrainMaps"
              icon="fa-light fa-arrow-down-up-lock">
           </button>
          </div>
         </div>
      </div>

      <cae-selected-outline-pass [hiddenEdgeColor]="'#00ffff'" [edgeThickness]="3" [edgeStrength]="5"></cae-selected-outline-pass>
      <cae-hovered-outline-pass [visibleEdgeColor]="'#00ffff'" [hiddenEdgeColor]="'#00ffff'" [edgeThickness]="3" [edgeStrength]="5"></cae-hovered-outline-pass>
      <cae-outline-pass [visibleEdgeColor]="'#000000'" [hiddenEdgeColor]="'#000000'"></cae-outline-pass>
      <cae-ambient-occlusion-pass #ambientOcc [aoParameters]="aoParameters" [ignoreNames]="['measures', 'arrows']"></cae-ambient-occlusion-pass>
      <!-- For anti alias (to use it, add it to ui module) -->
      <!-- <cae-fxaa-pass></cae-fxaa-pass> -->
    </cae-view>
    <cae-view
      [gridHeight]="1"
      [gridWidth]="1"
      [gridColumnStart]="6"
      [gridRowStart]="1"
      [scene]="scene2"
      [homeButton]="true"
      [homeVector]="specHomeVector"
      [targetCameraControls]="cameraControlsComponent"
    >
      <cae-camera-controls
        [link]="cameraControlsComponent"
        [clickToZoom]="false"
        [lockOrbit]="true"
        [lockZoom]="true"
        [lockMove]="true"
        [isViewCube]="true"
      ></cae-camera-controls>
      <cae-scene #scene2>
        <cae-view-cube [view]="mainView"></cae-view-cube>
      </cae-scene>
    </cae-view>
  </cae-views-container>
</cae-toolframe>
