import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCommasWithNewlines',
  standalone: true,
})
export class ReplaceCommasWithNewlinesPipe implements PipeTransform {

  transform(value: string[]): string {
    // const dataString = value.join('\n');
    // return dataString.replace(/\n/g, '<br>');
    // return dataString.replace(/\n/g, '<br>&nbsp;&nbsp;&nbsp;&nbsp;');
  return value.map(line => {
    let content = line.split(":");
    return `<span class="line-key">${content[0]}: <span class="line-value">${content[1]}</span></span>`
    }).join('<br>');

  }

}