import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  HostListener,
  NgZone
} from '@angular/core';
import {
  OnshapeAssembly,
  Configurator,
  OnshapeConfiguratorService,
  MESHFACTORY,
  MATERIALFACTORY,
  AlertService,
  OnshapeAssemblyComponent,
  AbstractObject3D,
  AmbientOcclusionPassDirective,
  MeshService,
  MaterialType,
  ViewComponent,
  HoverService,
  ExplodeService,
  ExplodeSolutions,
  ComponentCanDeactivate,
  automaticUnsubscribe,
  DeviceService
} from '@harmanpa/ng-cae';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {Observable, first, interval, map, mergeMap} from 'rxjs';
import { CADConfiguration } from '@harmanpa/ng-cae/lib/types/cADConfiguration';
import { MeshFactory } from 'src/app/shared/services/mesh-factory.service';
import { MaterialFactory } from 'src/app/shared/services/material-factory.service';
import {DefaultService, OnshapeConfigurator} from 'generated-src';
import { Color, LineDashedMaterial, Object3D, Vector3 } from 'three';
import { SceneManipulation} from 'src/app/shared/services/scene-manipulation.service';
import { ConnectivityService } from '../../shared/services/connectivity.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  providers: [
    { provide: MESHFACTORY, useClass: MeshFactory },
    {
      provide: MATERIALFACTORY,
      useClass: MaterialFactory,
    },
  ],
})
export class ViewerComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {

  @Input() configuredAssembly: OnshapeAssembly;
  @ViewChild('assy') assy: OnshapeAssemblyComponent;
  @ViewChild('ambientOcc') ambientOcc: AmbientOcclusionPassDirective;
  @ViewChild('mainView') view: ViewComponent;

  apiConfiguration: string;
  appVersion: string;
  configuration: CADConfiguration;
  configurator: Configurator;
  description: string;
  halfpi: number = Math.PI / 2;
  mobileDevice: boolean = false;
  minushalfpi: number = -1 * this.halfpi;
  specHomeVector = new Vector3(0.5, 0.25, 0.5).normalize();
  specPosition = this.specHomeVector.multiplyScalar(3);
  white: Color = new Color(1, 1, 1);
  sceneBackground = new Color('#F9F9F9');
  /** Parameters for Ambient occlusion */
  aoParameters = {
    blendIntensity: 0.9,
    radius: 0.37,
    scale: 0.65,
    screenSpaceRadius: true
  };
  // Materials for measures
  blue = new Color(0, 0, 1);
  arrowMaterial = new LineDashedMaterial({
    color: this.blue
  });
  arrowMaterialHidden = new LineDashedMaterial({
    color: this.blue,
    depthTest: false,
    depthWrite: false,
    opacity: 0.5,
    transparent: true
  });
  renderTypes = ['solidEdges', 'solidHiddenEdges', 'wireframe'];
  /**Keep tracks of online status */
  isOnline = true;

  /** Boolean that keeps track if it is showing parts or not (For isolate fittings) */
  isShowingParts = true;
  /** Boolean that keeps track if it is showing grains */
  isShowingGrain = false;
  /** Contains the clicked objects to add to the measures component */
  clickedObjects: AbstractObject3D<Object3D>[];
  ignoreNextMaterialChange = false;
  /** If true, then this object has fittings, so enable the button */
  hasIsolateFittings = false;
  /** If true, then this object has grain map, so enable the button */
  hasGrainMaps = false;
  /** If true, then we are isolating an instance */
  isIsolatingInstance = false;
  /** If true, then we are exploding the scene */
  isExploding = false;
  /** if true, objects are loaded (needed for measures and explode to wait for the surveys to be filtered) */
  objectsLoaded = false;

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private api: DefaultService,
    private deviceService: DeviceService,
    private configuratorService: OnshapeConfiguratorService,
    private http: HttpClient,
    private titleService: Title,
    private meshService: MeshService,
    private sceneManipulation: SceneManipulation,
    private hoverService: HoverService,
    private explodeService: ExplodeService,
    private connectivityService: ConnectivityService
  ) {}

  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return this.isOnline;
  }

  ngOnInit(): void {
    this.getAppVersion();
    this.checkConnection();
    this.observeMobile();
    this.route.url.pipe(
        mergeMap(url => this.http
        .get<Configurator>(
            '/api/viewers/' + url.map((segment) => segment.toString()).join('/')
        ))).subscribe({
      next: (configurator) => {
        this.configurator = configurator;
        this.titleService.setTitle(
          this.configurator.documentName + ' - ' + this.configurator.elementName
        );
        this.configuration =
          this.configuratorService.applyStringOverrideForDefaults(
            this.apiConfiguration,
            this.configuratorService.getDefaultConfiguration(configurator)
          );
        this.updateAssembly();
        this.getNotesFromAssembly().subscribe(
          (res) => (this.description = res || null)
        );
      },
      error: (err) => {
        console.log(err);
      },
    });

    // On material change
    this.meshService.getMaterialType().subscribe((materialType) => {
      if (!this.ignoreNextMaterialChange) {
        // If we are isolating fitting, undo it
        if (!this.isShowingParts) {
          this.isShowingParts = true;
          this.sceneManipulation.isShowingParts = true;
          this.sceneManipulation.hideShowParts(this.assy, this.isShowingParts);
        }

        // If we are showing grains, undo it
        if (this.isShowingGrain) {
          this.isShowingGrain = false;
          this.sceneManipulation.hideShowGrainMap(this.isShowingGrain);
        }
      }

      /*If we are isolating instances, we need to update the lines so the invisible lines
        are not selectable due to a line2 bug. we also need to update the parts layer for the invisible ones to be ignored */
      if (this.isIsolatingInstance) {
        interval(0).pipe(first()).subscribe(() => { // Wait for one frame for this to be called after the mesh-factory
          this.sceneManipulation.updateLinesIsolating();
        });
      }

      this.ignoreNextMaterialChange = false;
    });

    // Listens for has fittings to enable buttons if there are fittings
    this.sceneManipulation.observeHasFittings().subscribe(() => this.hasIsolateFittings = true);

    // Listens for has grainMaps to enable buttons if there are grainMaps
    this.sceneManipulation.observeHasGrainMap().subscribe(() => this.hasGrainMaps = true);
    
    //Listens for objects to be loaded for measure and explode button to only be enabled after the surveys are enabled
    this.sceneManipulation.observeHasObjectLoaded().subscribe(() => this.objectsLoaded = true);

    // Listens for isolating instance
    this.sceneManipulation.observeIsolatingInstance().subscribe((isIsolating) => {
      this.isIsolatingInstance = isIsolating;
      if (this.isExploding) { // Hide walls if exploding
        this.sceneManipulation.hideSurveys();
      }
    });

    this.explodeService.observeExplosions().subscribe((exploded) => {
      // If grain is shown, remove it
      if (this.isShowingGrain) {
        this.toggleGrain();
      }

      this.isExploding = exploded !== 0;
      if (!this.isIsolatingInstance) {  // If it is not isolating instance, then toggle survey on explosion change
        if (this.isExploding) {
          this.sceneManipulation.hideSurveys();
        } else {
          // Wait for animation to complete
          interval(1000).pipe(first()).subscribe(() => {
            if (!this.isExploding) {
              this.sceneManipulation.showSurveys();
            }
          });
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.sceneManipulation.setView(this.view);
  }

  /**Check internet connection */
  checkConnection(): void {
    this.connectivityService.observeOnline().subscribe(status => {
        this.isOnline = status;
        console.log('checkConnection', this.isOnline);
    });
  }

  updateAssembly(): void {
    console.log('Updating assembly');
    this.configuratorService
      .getAssembly(this.configurator, this.configuration, true, true, true)
      .subscribe({
        next: (configuredAssembly) => {
          this.configuredAssembly = configuredAssembly;
          console.log('Configured Assembly:', this.configuredAssembly);

          // Get parts from assembly
          this.sceneManipulation.getParts(this.configuredAssembly.bom.rows, this.assy);
          // Calculate the exploded views
          this.getExplodeSolutions(this.configurator,
              this.configuratorService.getConfigurationString(this.configuration))
              .subscribe({
                next: (es) => {
                  this.explodeService.setSolution(es);
                },
                error: (err) => {
                  console.log(err);
                },
              });
        },
        error: (err) => {
          console.log(err);
        },
      });

  }

  /**
   * Calculate and return object that can explode the current assembly
   *
   * @param configurator
   * @param configuration
   */
  getExplodeSolutions(configurator: OnshapeConfigurator,
              configuration: string = 'default'): Observable<ExplodeSolutions> {
    return this.http.get<ExplodeSolutions>(`/api/explode/d/${configurator.documentId}/${configurator.wvmType.charAt(0).toLowerCase()}/${configurator.wvmId}/e/${configurator.elementId}/c/${configuration}`);
  }

  /**get app version */
  getAppVersion(): void {
    this.api.getAppVersion().subscribe({
      next: (res: any) => {
        this.appVersion = res['app'];
      },
      error: (err) => {
        this.alertService.error(err);
      },
    });
  }

  /**
   * Toggles Parts (to show only fitting)
   */
  toggleParts(event: Event): void {
    event.stopPropagation();

    // If grain is shown, remove it
    if (this.isShowingParts && this.isShowingGrain) {
      this.toggleGrain(event);
    }

    // Reset clicked objects scene color (to prevent bugs)
    this.clickedObjects?.forEach((part) => {
      this.hoverService.resetHoverClick(part.getObject(), false);
    });

    // Update state variables
    this.ignoreNextMaterialChange = true;
    this.isShowingParts = !this.isShowingParts;
    this.sceneManipulation.isShowingParts = this.isShowingParts;

    // Resets scene to SolidWithEdges to prevent bugs
    this.meshService.setMaterialType(MaterialType.SolidWithEdges);
    // Toggle parts of scene to only show fittings
    this.sceneManipulation.hideShowParts(this.assy, this.isShowingParts);

    // Set clicked objects scene color again
    this.clickedObjects?.forEach((part) => {
      this.hoverService.hoverClick(part.getObject(), true);
    });
  }

  getNotesFromAssembly(): Observable<string> {
    return this.http
      .get('/api/documents/' + this.configurator.documentId + '/versions')
      .pipe(
        map((result: { id: string; description: string }[]) => {
          return result.find(
            (version) => version.id === this.configurator.wvmId
          ).description;
        })
      );
  }

  /**
   * Sets the clicked objects (for measures)
   * @param objects - The objects to measure
   */
  setClickedObjects(objects: AbstractObject3D<Object3D>[]): void {
    this.clickedObjects = objects;
  }

  /**
   * Toggles Grain Direction
   */
  toggleGrain(event?: Event): void {
    event?.stopPropagation();

    // If parts are being hidded (only showing fittings), show them
    if (!this.isShowingGrain && !this.isShowingParts) {
      this.toggleParts(event);
    }

    // Resets scene to SolidWithEdges to fix bugs
    this.ignoreNextMaterialChange = true;
    this.meshService.setMaterialType(MaterialType.SolidWithEdges);

    // Toogle grains
    this.isShowingGrain = !this.isShowingGrain;
    this.sceneManipulation.hideShowGrainMap(this.isShowingGrain);
  }

  observeMobile(): void {
    this.deviceService.observeMobileSize()
      .pipe(automaticUnsubscribe(this))
      .subscribe((isMobile: boolean) => {
        if (isMobile) {
          this.mobileDevice = true;
        } else {
          this.mobileDevice = false;
        }
      });
  }
}
