/**
 * fjs-viewer API
 * CAD Viewer for FJS
 *
 * OpenAPI spec version: 1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CalculationSummary { 
    id?: string;
    documentType?: string;
    type?: string;
    documentId?: string;
    version?: string;
    path?: string;
    owner?: string;
    status?: CalculationSummary.StatusEnum;
    created?: Date;
    progress?: number;
    logs?: Array<string>;
    started?: Date;
    updated?: Date;
    queue?: string;
}
export namespace CalculationSummary {
    export type StatusEnum = 'Submitted' | 'Blocked' | 'Queued' | 'Scheduled' | 'Running' | 'Complete' | 'Failed';
    export const StatusEnum = {
        Submitted: 'Submitted' as StatusEnum,
        Blocked: 'Blocked' as StatusEnum,
        Queued: 'Queued' as StatusEnum,
        Scheduled: 'Scheduled' as StatusEnum,
        Running: 'Running' as StatusEnum,
        Complete: 'Complete' as StatusEnum,
        Failed: 'Failed' as StatusEnum
    };
}
